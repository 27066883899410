import React, { Component } from "react";
import { List, Segment } from "semantic-ui-react";
import { default as RepView } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import { connect } from "react-redux";
import { selectReportView } from "../actions";

class ReportView extends Component {
  onReportViewChange = value => {
    this.props.selectReportView(value);
  };

  componentDidMount() {
    if (!this.props.selectedReportView)
      this.props.selectReportView("condensed");
  }

  render() {
    return (
      <Segment raised style={{ minHeight: "27vh" }}>
        <h5>
          <b>Select the Report View</b>
        </h5>
        <List>
          <RepView
            label="Full View"
            value="full"
            handlechange={() => this.onReportViewChange("full")}
            checked={this.props.selectedReportView === "full"}
          />
          <RepView
            label="Condensed View"
            value="condensed"
            handlechange={() => this.onReportViewChange("condensed")}
            checked={this.props.selectedReportView === "condensed"}
          />
        </List>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return { selectedReportView: state.selectedReportView };
};
export default connect(
  mapStateToProps,
  { selectReportView }
)(ReportView);
