export const selectedStaffFunction = "STAFF_FUNCTION_SELECTED";
export const fetchStaffFunctions = "FETCH_STAFF_FUNCTIONS";
export const fetchManagersByDepartment = "FETCH_MANAGERS";
export const addManager = "STAFF_MANAGER_SELECTED";
export const removeManager = "REMOVE_MANAGER";
export const fetchAVData = "FETCH_AV_DATA";
export const generateavpdf = "GENERATE_AV_PDF";
export const generateavexcel = "GENERATE_AV_EXCEL";
export const selectedAssignmentVehicle = "ASSIGNMENT_VEHICLE_SELECTED";
export const fetchLAData = "FETCH_LA_DATA";
export const generatelapdf = "GENERATE_LA_PDF";
export const generatelaexcel = "GENERATE_LA_EXCEL";
export const addAVManager = "ADD_AV_STAFF_MANAGER";
export const removeAVManager = "REMOVE_AV_MANAGER";
export const addAV = "ADD_AV";
export const removeAV = "REMOVE_AV";
export const addAVStaff = "ADD_AV_STAFF";
export const removeAVStaff = "REMOVE_AV_STAFF";
export const addAVLocation = "ADD_AV_LOCATION";
export const removeAVLocation = "REMOVE_AV_LOCATION";
export const addCostCenter = "ADD_AV_COST_CENTER";
export const removeCostCenter = "REMOVE_AV_COST_CENTER";
export const addCompletionStatus = "ADD_AV_COMPLETION_STATUS";
export const removeCompletionStatus = "REMOVE_AV_COMPLETION_STATUS";
export const removeAllSelectedManagers = "REMOVE_ALL_SELECTED_MANAGERS";
export const avExecutionTime = "AV_EXECUTION_TIME";
export const laExecutionTime = "LA_EXECUTION_TIME";
