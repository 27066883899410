import React, { Component, Fragment } from "react";
import { List, Segment } from "semantic-ui-react";
import { default as RegBody } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import { connect } from "react-redux";
import { addRegulatoryBody, removeRegulatoryBody } from "../actions";
class RegulatoryBody extends Component {
  onRegulatoryBodyChange = (e, regBody) => {
    if (!e.target.previousSibling.checked) {
      this.props.addRegulatoryBody(regBody);
    } else {
      this.props.removeRegulatoryBody(regBody);
    }
  };

  renderRegulatoryBodies() {
    return this.props.regBodies.map(r => (
      <RegBody
        key={r.regBody}
        checked={this.props.selectedRegulatoryBodies.includes(r.regBody)}
        classname=""
        label={r.regBody}
        name={`${r.regBody}_${r.regBodyId}`}
        value={r.regBody}
        handlechange={e =>
          this.onRegulatoryBodyChange(e, r.regBody)
        }
      />
    ));
  }

  render() {
    if (this.props.regBodies) {
      return (
        <Segment raised >
          <h5>
            <b>Select Regulatory Body</b>
          </h5>
          <div className="scroll" style={{ maxHeight: "20vh" }}>
            <List>{this.renderRegulatoryBodies()}</List>
          </div>
        </Segment>
      );
    }
    return <Fragment></Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    selectedRegulatoryBodies: state.selectedRegulatoryBodies
  };
};
export default connect(
  mapStateToProps,
  { addRegulatoryBody, removeRegulatoryBody }
)(RegulatoryBody);
