import React, { Component, Fragment } from "react";
import { List, Segment } from "semantic-ui-react";
import { default as AssignStatus } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import { connect } from "react-redux";
import { addAssignmentStatus, removeAssignmentStatus } from "../actions";
class AssignmentStatus extends Component {
  onAssignmentStatusChange = (e, status, id) => {
    //status = { status, id };
    if (!e.target.previousSibling.checked) {
      this.props.addAssignmentStatus(status);
    } else {
      this.props.removeAssignmentStatus(status);
    }
  };

  renderAssignmentStatuses() {
    return this.props.assignmentStatuses
      .filter(as => as.assignmentStatus.toLowerCase() !== "Not Available".toLowerCase())
      .map(a => (
        <div
          key={a.assignmentStatusId}
          style={{ display: "inline-block", minWidth: "120px" }}
        >
          <AssignStatus
            checked={this.props.selectedAssignmentStatuses.includes(
              a.assignmentStatus
            )}
            key={a.assignmentStatusId}
            classname=""
            label={a.assignmentStatus}
            name={`${a.assignmentStatus}_${a.assignmentStatusId}`}
            value={a.assignmentStatusId}
            handlechange={e =>
              this.onAssignmentStatusChange(
                e,
                a.assignmentStatus,
                a.assignmentStatusId
              )
            }
          />
        </div>
      ));
  }

  render() {
    if (this.props.assignmentStatuses) {
      return (
        <Segment raised>
          <h5>
            <b>Select Assignment Status</b>
          </h5>
          <div className="scroll" style={{ maxHeight: "20vh" }}>
            <List horizontal>{this.renderAssignmentStatuses()}</List>
          </div>
        </Segment>
      );
    }
    return <Fragment></Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    selectedAssignmentStatuses: state.selectedAssignmentStatuses
  };
};
export default connect(
  mapStateToProps,
  { addAssignmentStatus, removeAssignmentStatus }
)(AssignmentStatus);
