import React, { Component } from "react";
import _isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import withLoader from "../Common/controls/Loader/Loader";
import {
  downloadSignedURLFile,
  handleExcelPdfErrorresponses,
  downloadSignedURLJsonFile
} from "../../helpers";
import {
  Icon,
  Grid,
  Container,
  Card,
  Button,
  Modal,
  Image,
  List,
  Checkbox,
  Segment
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import {
  fetchAVReportData,
  selectStaffFunction,
  generateavreportpdf,
  generateavreportexcel,
  selectAssignmentVehicle,
  fetchLAReportData,
  avManagerAdd,
  avManagerRemove,
  avAdd,
  avRemove,
  avStaffAdd,
  avStaffRemove,
  avLocationAdd,
  avLocationRemove,
  avCostCenterAdd,
  avCostCenterRemove
} from "./actions";
import Progressbar from "../Common/controls/Progressbar/Progressbar";
import iconFinderImage from "./images/iconfinder_Close_1031533.svg";
import GreenDot from "./images/green.png";
import RedDot from "./images/red.png";
import GreyDot from "./images/grey.png";
import GoldenDot from "./images/golden.png";
import { TAS_REPORT_FETCH_AV_DATA_FAIL } from "../Common/errorcodes/errorcodes";
import Message from "../Common/controls/Message/Message";
import ErrorMessage from "../Common/controls/Message/ErrorMessage";
import withFixedColumnsScrollEvent from "react-table-hoc-fixed-columns";
// import ExecutionTime from "../Common/controls/ExecutionTime/ExecutionTime";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

const ReactTableFixedColumns = withFixedColumnsScrollEvent(ReactTable);
var isIE = false || !!document.documentMode;
var isLargeMonitor =
  window.screen.width === 1920 && window.screen.height === 1080 ? true : false;

class TASAVReport extends Component {
  state = {
    avReportKey: Math.random(),
    avDataSuperSet: undefined,
    avDataObj: undefined
  };

  componentDidMount() {
    const { credential, avReportData } = this.props;
    if (credential && !avReportData) {
      this.fetchAVData();
    }
  }
  componentDidUpdate() {
    const { avReportData } = this.props;
    if (!avReportData) {
      this.fetchAVData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.avReportData, this.props.avReportData)) {
      nextProps.avReportData.Managers.map(manager => {
        nextProps.avManagerAdd(manager);
      });

      Object.keys(nextProps.avReportData.dataGroupedByAssignmentVehicle).map(
        av => {
          nextProps.avAdd(av);
        }
      );

      nextProps.avReportData.StaffList.map(staff => {
        nextProps.avStaffAdd(staff);
      });

      nextProps.avReportData.Locations.map(location => {
        nextProps.avLocationAdd(location);
      });

      nextProps.avReportData.CostCenters.map(cc => {
        nextProps.avCostCenterAdd(cc);
      });

      this.setState({
        avDataSuperSet: nextProps.avReportData.dataGroupedByAssignmentVehicle
      });

      this.filterAVData(
        cloneDeep(nextProps.avReportData.dataGroupedByAssignmentVehicle)
      );
    }
  }

  refreshAVReport = () => {
    this.setState({
      avReportKey: Math.random(),
      open: false
    });

    var avDataFiltered = cloneDeep(this.state.avDataSuperSet);

    Object.keys(this.state.avDataSuperSet).map(av => {
      if (this.props.avsSelected.filter(a => a === av).length > 0) {
        this.state.avDataSuperSet[av].MAVData.map(manager => {
          var filteredManagerIndex = avDataFiltered[av].MAVData.findIndex(
            x => x.MN === manager.MN
          );

          if (
            this.props.avSelectedManagers.filter(
              m => m === manager.MN + "  (" + manager.MId + ")"
            ).length > 0
          ) {
            manager.SAVData.map(staff => {
              //Staff filtration
              if (
                this.props.avSelectedStaffs.filter(
                  s => s === staff.SN + "  (" + staff.SId + ")"
                ).length === 0
              ) {
                avDataFiltered[av].MAVData[filteredManagerIndex].SAVData.splice(
                  avDataFiltered[av].MAVData[
                    filteredManagerIndex
                  ].SAVData.findIndex(x => x.SN === staff.SN),
                  1
                ); //Remove unselected staff
              }

              //Location Filtration
              if (
                this.props.avSelectedLocations.filter(s => s === staff.LOC)
                  .length === 0
              ) {
                if (
                  avDataFiltered[av].MAVData[
                    filteredManagerIndex
                  ].SAVData.findIndex(x => x.SN === staff.SN) !== -1
                ) {
                  avDataFiltered[av].MAVData[
                    filteredManagerIndex
                  ].SAVData.splice(
                    avDataFiltered[av].MAVData[
                      filteredManagerIndex
                    ].SAVData.findIndex(x => x.SN === staff.SN),
                    1
                  ); //Remove staff corresponding to unselected location
                }
              }

              //Cost Center Filtration
              if (
                this.props.avSelectedCostCenters.filter(s => s === staff.CC)
                  .length === 0
              ) {
                if (
                  avDataFiltered[av].MAVData[
                    filteredManagerIndex
                  ].SAVData.findIndex(x => x.SN === staff.SN) !== -1
                ) {
                  avDataFiltered[av].MAVData[
                    filteredManagerIndex
                  ].SAVData.splice(
                    avDataFiltered[av].MAVData[
                      filteredManagerIndex
                    ].SAVData.findIndex(x => x.SN === staff.SN),
                    1
                  ); //Remove staff corresponding to unselected Cost center
                }
              }
            });
          } else {
            avDataFiltered[av].MAVData.splice(
              avDataFiltered[av].MAVData.findIndex(x => x.MN === manager.MN),
              1
            ); //Remove unselected manager
          }

          if (
            avDataFiltered[av] !== undefined &&
            avDataFiltered[av].MAVData !== [] &&
            avDataFiltered[av].MAVData[filteredManagerIndex] !== undefined &&
            avDataFiltered[av].MAVData.length > 0 &&
            (avDataFiltered[av].MAVData[filteredManagerIndex].SAVData ===
              undefined ||
              avDataFiltered[av].MAVData[filteredManagerIndex].SAVData
                .length === 0)
          ) {
            avDataFiltered[av].MAVData.splice(filteredManagerIndex, 1); //Remove manager if they have 0 staff underneath
          }
        });
      } else {
        delete avDataFiltered[av]; //Remove unselected Assignment vehicle
      }

      if (
        avDataFiltered[av] !== undefined &&
        (avDataFiltered[av].MAVData === [] ||
          avDataFiltered[av].MAVData === undefined ||
          avDataFiltered[av].MAVData.length === 0)
      ) {
        delete avDataFiltered[av]; //Remove Assignment vehicle if it has 0 managers underneath
      }

      // If an AV is not assigned any of the staffs(in otherwords if an AV is unassigned for all the staffs), 
      // then do not display that AV.
      // This has to be de-aggregated from the Manager level and flag has to be 
      if (avDataFiltered[av] !== undefined && avDataFiltered[av].MAVData !== undefined &&
          avDataFiltered[av].MAVData !== []) 
      {
        let deleteAV = true;

        for (let manager of avDataFiltered[av].MAVData)
          deleteAV &= (manager.SAVData.findIndex(x => x.ST === "R" || x.ST === "G" || x.ST === "Y") === -1);  
                
        if (deleteAV === true)
          delete avDataFiltered[av]; 
      } 
    });

    //Recalculating completed,incomplete and totalqualified count after filtration
    Object.keys(avDataFiltered).map(av => {
      avDataFiltered[av].TQ = 0;
      avDataFiltered[av].MAVData.map(manager => {
        var completedCount = 0;
        manager.SAVData.map(staff => {
          if (staff.ST === "G") {
            completedCount++;
          }
        });
        avDataFiltered[av].TQ += completedCount;
      });
    });

    this.setState({ avDataObj: avDataFiltered }); //Set the filtered data to avDataObj
  };

  fetchAVData() {
    const { selectedStaffFunction } = this.props;
    var managerNames = "";

    managerNames = this.getFormattedManagers();
    this.props.fetchAVReportData(selectedStaffFunction, managerNames);
  }

  getFormattedManagers() {
    const { selectedManagers } = this.props;
    var managerNames = "";
    selectedManagers.forEach(element => {
      managerNames =
        managerNames + "'" + element.name.replace(/'/g, "\\'") + "',";
    });

    managerNames = managerNames.slice(0, managerNames.length - 1);
    return managerNames;
  }

  getManagersSelectedInFilter(avSelectedManagers) {
    var managerNames = "";
    avSelectedManagers.forEach(element => {
      managerNames = managerNames + "'" + element.replace(/'/g, "\\'") + "',";
    });

    managerNames = managerNames.slice(0, managerNames.length - 1);

    return managerNames;
  }

  getStaffsSelectedInFilter(avSelectedStaffs) {
    var staffs = "";
    avSelectedStaffs.forEach(element => {
      staffs = staffs + "'" + element.replace(/'/g, "\\'") + "',";
    });

    staffs = staffs.slice(0, staffs.length - 1);
    return staffs;
  }

  getAVsSelectedInFilter(avsSelected) {
    var avs = "";
    avsSelected.forEach(element => {
      avs = avs + "'" + element.replace(/'/g, "\\'") + "',";
    });

    avs = avs.slice(0, avs.length - 1);
    return avs;
  }

  getCostCentersSelectedInFilter(avSelectedCostCenters) {
    var costCenters = "";
    avSelectedCostCenters.forEach(element => {
      costCenters = costCenters + "'" + element.replace(/'/g, "\\'") + "',";
    });

    costCenters = costCenters.slice(0, costCenters.length - 1);
    return costCenters;
  }

  getlocationssSelectedInFilter(avSelectedLocations) {
    var locations = "";
    avSelectedLocations.forEach(element => {
      locations = locations + "'" + element.replace(/'/g, "\\'") + "',";
    });

    locations = locations.slice(0, locations.length - 1);
    return locations;
  }

  show = dimmer => () => this.setState({ dimmer, open: true });
  close = () => this.setState({ open: false });

  showLA(event, av, avKey, key) {
    var managerNames = "",
      staffNames = "",
      costcenters = "",
      locations = "";

    this.props.avSelectedManagers.forEach(element => {
      managerNames = managerNames + "'" + element.replace(/'/g, "\\'") + "',";
    });

    this.props.avSelectedStaffs.forEach(element => {
      staffNames = staffNames + "'" + element.replace(/'/g, "\\'") + "',";
    });

    this.props.avSelectedCostCenters.forEach(element => {
      costcenters =
        costcenters +
        "'" +
        (element !== null ? element.replace(/'/g, '"') : element) +
        "'" +
        ",";
    });

    this.props.avSelectedLocations.forEach(element => {
      locations =
        locations +
        "'" +
        (element !== null ? element.replace(/'/g, '"') : element) +
        "'" +
        ",";
    });

    managerNames = managerNames.slice(0, managerNames.length - 1);
    staffNames = staffNames.slice(0, staffNames.length - 1);
    costcenters = costcenters.slice(0, costcenters.length - 1);
    locations = locations.slice(0, locations.length - 1);

    this.props.selectAssignmentVehicle(av);
    event.preventDefault();
    const url = event.target.href;
    const data = {
      staffFunction: this.props.selectedStaffFunction,
      managers: managerNames,
      selectedAV: av,
      selectedAVKey: avKey,
      staffs: staffNames,
      costCenters: costcenters,
      locations: locations
    };
    window.localStorage.setItem(key, JSON.stringify(data));

    window.open(url, "_target");
  }

  handleAllAVChecked = (e, result) => {
    var assignmentList = document
      .getElementsByName("assignmentList")[0]
      .getElementsByClassName("item avItemList");

    if (result.checked) {
      for (var i = 0; i < assignmentList.length; i++) {
        assignmentList[i].children[0].children[0].checked = true;

        this.props.avAdd(assignmentList[i].children[0].children[0].value);
      }
    } else {
      for (var j = 0; j < assignmentList.length; j++) {
        assignmentList[j].children[0].children[0].checked = false;

        this.props.avRemove(assignmentList[j].children[0].children[0].value);
      }
    }
  };

  handleAllStaffChecked = (e, result) => {
    var staffList = document
      .getElementsByName("staffList")[0]
      .getElementsByClassName("item opsList");

    if (result.checked) {
      for (var i = 0; i < staffList.length; i++) {
        staffList[i].children[0].children[0].checked = true;
        this.props.avStaffAdd(staffList[i].children[0].children[0].value);
      }
    } else {
      for (var j = 0; j < staffList.length; j++) {
        staffList[j].children[0].children[0].checked = false;
        this.props.avStaffRemove(staffList[j].children[0].children[0].value);
      }
    }
  };

  onManagerChange = (e, result) => {
    const manager = result.value;
    if (result.checked) {
      this.props.avManagerAdd(manager);
    } else {
      this.props.avManagerRemove(manager);
    }
  };

  onAVChange = (e, result) => {
    const av = result.value;
    if (result.checked) {
      this.props.avAdd(av);
    } else {
      this.props.avRemove(av);
    }
  };

  onLocationChange = (e, result) => {
    const location = result.value;
    if (result.checked) {
      this.props.avLocationAdd(location);
    } else {
      this.props.avLocationRemove(location);
    }
  };

  onCostCenterChange = (e, result) => {
    const cc = result.value;
    if (result.checked) {
      this.props.avCostCenterAdd(cc);
    } else {
      this.props.avCostCenterRemove(cc);
    }
  };

  onStaffChange = (e, result) => {
    const staff = result.value;
    if (result.checked) {
      this.props.avStaffAdd(staff);
    } else {
      this.props.avStaffRemove(staff);
    }
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleExportPdfClick = async () => {
    try {
      this.props.showLoader();

      var returnValue = await this.props.generateavreportpdf(
        this.props.selectedStaffFunction,
        this.getManagersSelectedInFilter(this.props.avSelectedManagers),
        this.getStaffsSelectedInFilter(this.props.avSelectedStaffs),
        this.getAVsSelectedInFilter(this.props.avsSelected),
        this.getCostCentersSelectedInFilter(this.props.avSelectedCostCenters),
        this.getlocationssSelectedInFilter(this.props.avSelectedLocations)
      );
      if (returnValue === null)
        downloadSignedURLFile(this.props.avReportPdf.documentURL);
      else handleExcelPdfErrorresponses(returnValue);
    } finally {
      this.props.hideLoader();
    }
  };

  handleExportExcelClick = async () => {
    try {
      this.props.showLoader();

      var returnValue = await this.props.generateavreportexcel(
        this.props.selectedStaffFunction,
        this.getManagersSelectedInFilter(this.props.avSelectedManagers),
        this.getStaffsSelectedInFilter(this.props.avSelectedStaffs),
        this.getAVsSelectedInFilter(this.props.avsSelected),
        this.getCostCentersSelectedInFilter(this.props.avSelectedCostCenters),
        this.getlocationssSelectedInFilter(this.props.avSelectedLocations)
      );
      if (returnValue === null)
        downloadSignedURLFile(this.props.avReportExcel.documentURL);
      else handleExcelPdfErrorresponses(returnValue);
    } finally {
      this.props.hideLoader();
    }
  };

  filterAVData(avDataObj) {
    if (avDataObj !== undefined) {
      Object.keys(avDataObj).map(av => {
        avDataObj[av].TQ = 0;
        avDataObj[av].MAVData.map(manager => {
          var completedCount = 0;
          manager.SAVData.map(staff => {
            if (staff.ST === "G") {
              completedCount++;
            }
          });

          avDataObj[av].TQ += completedCount;
        });
      });
      this.setState({ avDataObj: avDataObj });
    }
  }

  renderModalDialog() {
    const { avDataObj, open, dimmer } = this.state;

    const { avReportData } = this.props;
    var managersObj,
      assignmentVehiclesObj,
      staffsObj,
      costcenterObj,
      locationObj;

    if (
      avReportData &&
      Object.keys(avReportData.dataGroupedByAssignmentVehicle).length > 0
    ) {
      managersObj = avReportData.Managers.sort();
      assignmentVehiclesObj = Object.keys(
        avReportData.dataGroupedByAssignmentVehicle
      );
      staffsObj = avReportData.StaffList.sort();
      costcenterObj = avReportData.CostCenters.sort();
      locationObj = avReportData.Locations.sort();

      return (
        <Card.Group className="alignment">
          <div className="legend">
            <Image src={GreenDot} className="statusImage" />
            <span>(Y) Completed</span>
          </div>
          <div className="legend">
            <Image src={RedDot} className="statusImage" />
            <span>(N) Incomplete</span>
          </div>
          <div className="legend">
            <Image src={GoldenDot} className="statusImage" />
            <span>(E) Expires in 3 days or less</span>
          </div>
          <div className="legend">
            <Image src={GreyDot} className="statusImage" />
            <span>(U) Not assigned</span>
          </div>
          <Button
            basic
            className="export-button"
            onClick={e => this.handleExportExcelClick()}
          >
            <Icon name="file excel outline" className="icon-color" />
            Export Excel
          </Button>
          <Button
            basic
            className="export-button"
            onClick={e => this.handleExportPdfClick()}
          >
            <Icon name="file pdf outline" className="icon-color" />
            Export PDF
          </Button>

          <Button onClick={this.show("inverted")} className="filter-styles">
            <Icon
              name="filter"
              className="filter-margin"
              onClick={this.close}
            />
          </Button>
          <Modal
            dimmer={dimmer}
            open={open}
            onClose={this.close}
            closeOnDimmerClick={false}
            className="modalAV"
            centered={false}
          >
            <Modal.Header>
              Training Assignment Status Report – AV
              <Image
                src={iconFinderImage}
                onClick={this.close}
                className="close"
              />
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Segment>
                        <b className="labelheading">Select Staff's Manager :</b>
                        <div>
                          <List horizontal className="scroll managerList">
                            {managersObj.map((manager, i) => (
                              <List.Item className="opsList" key={i}>
                                <Checkbox
                                  label={manager}
                                  name={manager}
                                  value={manager}
                                  onChange={this.onManagerChange}
                                  checked={
                                    this.props.avSelectedManagers !==
                                      undefined &&
                                    this.props.avSelectedManagers.filter(
                                      m => m === manager
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                />
                              </List.Item>
                            ))}
                          </List>
                        </div>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  <Segment className="assignmentSegment">
                    <Grid.Row>
                      <b className="labelheading marginleft">
                        Select one or more Assignments :
                      </b>

                      <Checkbox
                        label="Select All"
                        name="SelectAll"
                        id="selectAll"
                        className="selectAll"
                        onChange={this.handleAllAVChecked}
                        checked={
                          this.props.avsSelected !== undefined &&
                          assignmentVehiclesObj !== undefined &&
                          this.props.avsSelected.length ===
                            assignmentVehiclesObj.length
                        }
                      />
                    </Grid.Row>
                    <Grid.Row className="assignmentScrollList assignmentmargin">
                      <Grid.Column width={16}>
                        <List horizontal name="assignmentList">
                          {assignmentVehiclesObj.map((av, i) => (
                            <List.Item className="avItemList" key={i}>
                              <Checkbox
                                label={
                                  avReportData.dataGroupedByAssignmentVehicle[
                                    av
                                  ].AV
                                }
                                name={av}
                                value={av}
                                onChange={this.onAVChange}
                                checked={
                                  this.props.avsSelected !== undefined &&
                                  this.props.avsSelected.filter(m => m === av)
                                    .length > 0
                                    ? true
                                    : false
                                }
                              />
                            </List.Item>
                          ))}
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                  </Segment>
                </Grid>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4} className="filterSegmentColumn">
                      <Segment className="filterSegment">
                        <b className="labelheading">Location:</b>
                        <List horizontal className="scroll listElements">
                          {locationObj.map((location, i) => (
                            <List.Item className="opsList" key={i}>
                              <Checkbox
                                label={location}
                                name={location}
                                value={location}
                                onChange={this.onLocationChange}
                                checked={
                                  this.props.avSelectedLocations !==
                                    undefined &&
                                  this.props.avSelectedLocations.filter(
                                    m => m === location
                                  ).length > 0
                                    ? true
                                    : false
                                }
                              />
                            </List.Item>
                          ))}
                        </List>
                      </Segment>
                    </Grid.Column>

                    <Grid.Column width={7} className="filterSegmentColumn">
                      <Segment className="filterSegment">
                        <b className="labelheading">Cost Center of Staff :</b>
                        <List horizontal className="scroll listElements">
                          {costcenterObj.map((cc, i) => (
                            <List.Item className="costCenterOpsList" key={i}>
                              <Checkbox
                                label={cc}
                                name={cc}
                                value={cc}
                                onChange={this.onCostCenterChange}
                                checked={
                                  this.props.avSelectedCostCenters !==
                                    undefined &&
                                  this.props.avSelectedCostCenters.filter(
                                    m => m === cc
                                  ).length > 0
                                    ? true
                                    : false
                                }
                              />
                            </List.Item>
                          ))}
                        </List>
                      </Segment>
                    </Grid.Column>

                    <Grid.Column width={5} className="filterSegmentColumn">
                      <Segment className="filterSegment">
                        <b className="labelheading">Staff :</b>
                        <Checkbox
                          label="Select All"
                          name="SelectAllStaff"
                          onChange={this.handleAllStaffChecked}
                          className="selectallStaff"
                          checked={
                            this.props.avSelectedStaffs !== undefined &&
                            staffsObj !== undefined &&
                            this.props.avSelectedStaffs.length ===
                              staffsObj.length
                          }
                        />
                        <List
                          horizontal
                          className="scroll listElements"
                          name="staffList"
                        >
                          {staffsObj.map((staff, i) => (
                            <List.Item className="opsList" key={i}>
                              <Checkbox
                                label={staff}
                                name={staff}
                                value={staff}
                                onChange={this.onStaffChange}
                                checked={
                                  this.props.avSelectedStaffs !== undefined &&
                                  this.props.avSelectedStaffs.filter(
                                    m => m === staff
                                  ).length > 0
                                    ? true
                                    : false
                                }
                              />
                            </List.Item>
                          ))}
                        </List>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Description>
              <Button className="button-hover" basic onClick={this.close}>
                Cancel
              </Button>
              <Button
                className="button-hover"
                basic
                onClick={() => this.refreshAVReport()}
              >
                Apply
              </Button>
            </Modal.Content>
          </Modal>
        </Card.Group>
      );
    }
  }

  getUniqueManagers() {
    var managerStaffObj = [];
    const { avReportData } = this.props;
    if (
      avReportData &&
      Object.keys(avReportData.dataGroupedByAssignmentVehicle).length > 0
    ) {
      Object.keys(this.state.avDataObj).map((assignmentvehicle, i) => {
        const avData = this.state.avDataObj[assignmentvehicle];
        avData.MAVData.map((manager, j) => {
          const found = managerStaffObj.some(el => el.MN === manager.MN);
          if (!found) {
            manager.SAVData.sort(this.compareStaffs);
            managerStaffObj.push(manager);
          }
        });
      });
    }
    managerStaffObj.sort(this.compareManagers);
    return managerStaffObj;
  }

  compareManagers(a, b) {
    const genreA = a.MN;
    const genreB = b.MN;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  compareStaffs(a, b) {
    const genreA = a.SN;
    const genreB = b.SN;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  renderHeaderData() {
    const managerStaffObj = this.getUniqueManagers();

    var headerData = [];
    headerData.push({
      Header: "Manager Name",
      fixed: "left",
      columns: [
        {
          Header: "Assignment Vehicles",
          accessor: "av",
          width: 300
        },
        {
          Header: "Total Qualified",
          accessor: "tq",
          width: 100
        }
      ]
    });

    managerStaffObj.map(manager => {
      manager.SAVData.map(staff => {
        headerData.push({
          Header: manager.MN + " (" + manager.MId + ")",
          id: manager.MId,
          columns: [
            {
              Header: staff.SN + " (" + staff.SId + ")",
              accessor: String(staff.SId),
              id: staff.SId,
              width: 120,
              sortable: false
            }
          ]
        });
      });
    });

    return headerData;
  }

  renderScrollableData() {
    const managerStaffObj = this.getUniqueManagers();
    var reportBodyData = [];

    Object.keys(this.state.avDataObj)
      // .sort()
      .map((assignmentvehicleKey, i) => {
        const avData = this.state.avDataObj[assignmentvehicleKey];
        var outputData = {};

        outputData["av"] = (
          <Link
            className="avNameLink"
            onClick={e => this.showLA(e, avData.AV, assignmentvehicleKey, i)}
            to={{
              pathname: "/TASLAReport",
              search: `?key=${i}`
            }}
            target="_blank"
          >
            {avData.AV}
          </Link>
        );
        outputData["tq"] = avData.TQ;

        managerStaffObj.map(headermanager => {
          var matchedManager = avData.MAVData.filter(
            el => el.MId === headermanager.MId
          );

          if (matchedManager.length > 0) {
            headermanager.SAVData.map((staff, k) => {
              var matchedStaffStatus = matchedManager[0].SAVData.filter(
                el => el.SId === staff.SId
              )[0].ST;

              outputData[staff.SId] = (
                <Image
                  src={
                    matchedStaffStatus === "G"
                      ? GreenDot
                      : matchedStaffStatus === "R"
                      ? RedDot
                      : matchedStaffStatus === "Y"
                      ? GoldenDot
                      : GreyDot
                  }
                  className="statusAlign"
                />
              );
            });
          } else {
            headermanager.SAVData.map((staff, k) => {
              outputData[staff.SId] = (
                <Image src={GreyDot} className="statusAlign" />
              );
            });
          }
        });

        reportBodyData.push(outputData);
      });

    return reportBodyData;
  }

  render() {
    const { avDataObj, avDataSuperSet } = this.state;
    const { avReportData, errors } = this.props;

    if (
      errors &&
      errors.find(code => code.errorCode === TAS_REPORT_FETCH_AV_DATA_FAIL) &&
      errors.filter(code => code.errorCode === TAS_REPORT_FETCH_AV_DATA_FAIL)[0]
        .data
    ) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching AV report data"
          errorDescription={
            errors.filter(
              code => code.errorCode === TAS_REPORT_FETCH_AV_DATA_FAIL
            )[0].data
          }
        />
      );
    }

    if (avReportData) {
      if (avDataSuperSet && Object.values(avDataSuperSet).length > 0) {
        if (avDataObj && Object.values(avDataObj).length > 0) {
          return (
            <Container className="top" key={this.state.avReportKey}>
              <div className="instruction">
                <span>
                  For latest course assignment/completion status you must go to
                  LMS.
                </span>
                {this.renderModalDialog()}
                <div>
                  Information on this report reflects LMS data as of&nbsp;
                  {avReportData.lmsDataRefresh}
                </div>
              </div>
              <ReactTableFixedColumns
                data={this.renderScrollableData()}
                columns={this.renderHeaderData()}
                // defaultPageSize={(ios? 10:(isIE ? 5 : 50))}
                defaultPageSize={isLargeMonitor ? 10 : isIE ? 5 : 10}
                className="fixedTable"
              />

              {/* <ExecutionTime time={this.props.avQueryExecutionTime} /> */}
            </Container>
          );
        }
        return (
          <Container className="top" key={this.state.avReportKey}>
            {this.renderModalDialog()}
            <Message display="No records found" />
          </Container>
        );
      }

      return (
        <Container className="top" key={this.state.avReportKey}>
          <Message display="No records found" />
        </Container>
      );
    }

    return (
      <Container className="top">
        <React.Fragment>
          <Progressbar Message="AV Report" />
        </React.Fragment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    credential: state.credential,
    selectedStaffFunction: state.selectedStaffFunction,
    avReportData: state.avReportData,
    selectedManagers: state.selectedManagers,
    avReportPdf: state.avpdf,
    avReportExcel: state.avexcel,
    selectedAssignmentVehicle: state.selectedAssignmentVehicle,
    laReportData: state.laReportData,
    avSelectedManagers: state.selectedAVManagers,
    avsSelected: state.selectedAVs,
    avSelectedStaffs: state.selectedStaffs,
    avSelectedLocations: state.selectedLocations,
    avSelectedCostCenters: state.selectedCostCenters,
    errors: state.errors,
    avQueryExecutionTime: state.avQueryExecutionTime
  };
};

export default withLoader(
  connect(
    mapStateToProps,
    {
      selectStaffFunction,
      fetchAVReportData,
      generateavreportpdf,
      generateavreportexcel,
      selectAssignmentVehicle,
      fetchLAReportData,
      avManagerAdd,
      avManagerRemove,
      avAdd,
      avRemove,
      avStaffAdd,
      avStaffRemove,
      avLocationAdd,
      avLocationRemove,
      avCostCenterAdd,
      avCostCenterRemove
    }
  )(TASAVReport)
);
