import {
  selectedStaffFunction,
  fetchStaffFunctions,
  fetchManagersByDepartment,
  addManager,
  removeManager,
  fetchAVData,
  generateavpdf,
  generateavexcel,
  selectedAssignmentVehicle,
  fetchLAData,
  generatelaexcel,
  generatelapdf,
  addAVManager,
  removeAVManager,
  addAVStaff,
  removeAVStaff,
  addAV,
  removeAV,
  addAVLocation,
  removeAVLocation,
  addCostCenter,
  removeCostCenter,
  addCompletionStatus,
  removeCompletionStatus,
  removeAllSelectedManagers,
  avExecutionTime,
  laExecutionTime
} from "./types";
import { addError, removeError } from "../../Common/actions/types";
import axios from "axios";
import config from "../../../.samples.config";
import {
  TAS_REPORT_FETCH_STAFF_FUNCTIONS_FAIL,
  TAS_REPORT_FETCH_STAFF_MANAGERS_FAIL,
  TAS_REPORT_FETCH_AV_DATA_FAIL,
  TAS_REPORT_FETCH_LA_DATA_FAIL,
  TAS_REPORT_FETCH_AV_EXCEL_DATA_FAIL,
  TAS_REPORT_FETCH_AV_PDF_DATA_FAIL,
  TAS_REPORT_FETCH_LA_EXCEL_DATA_FAIL,
  TAS_REPORT_FETCH_LA_PDF_DATA_FAIL
} from "../../Common/errorcodes/errorcodes";
import { downloadSignedURLJsonFile } from "../../../helpers";

export const signIn = () => {
  return {
    type: "SIGN_IN"
  };
};

export const fetchDepartments = staffId => async (dispatch, getState) => {
  try {
    const response = await apiALB(getState().credential.token).get(
      "/get-all-departments?managername=" + staffId
    );
    dispatch({ type: fetchStaffFunctions, payload: response.data });
  } catch (err) {
    dispatch({
      type: addError,
      payload: {
        errorCode: TAS_REPORT_FETCH_STAFF_FUNCTIONS_FAIL,
        data: err
      }
    });
  }
};

export const fetchManagers = departmentname => async (dispatch, getState) => {
  try {
    var requestGetParameters = {
      params: {
        departmentname: departmentname
      }
    };
    const response = await apiALB(getState().credential.token).get(
      "/get-managers-by-department",
      requestGetParameters
    );
    console.log("Selected Staff Function:" + departmentname);
    dispatch({ type: fetchManagersByDepartment, payload: response.data });
  } catch (err) {
    console.log("Error invoking fetchManagers action." + err);
    dispatch({
      type: addError,
      payload: {
        errorCode: TAS_REPORT_FETCH_STAFF_MANAGERS_FAIL,
        data: err
      }
    });
  }
};

export const selectStaffFunction = staffFunction => {
  return {
    type: selectedStaffFunction,
    payload: staffFunction
  };
};

export const ManagerSelect = manager => {
  return {
    type: addManager,
    payload: manager
  };
};

export const ManagerRemove = manager => {
  return {
    type: removeManager,
    payload: manager
  };
};

export const fetchAVReportData = (departmentname, managerNames) => async (
  dispatch,
  getState
) => {
  try {
    let data = JSON.stringify({
      departmentname: departmentname,
      managernames: managerNames
    });
    console.log("Json Input: " + data);
    const response = await apiALB(getState().credential.token).post(
      "/generate/av-report",
      data
    );
    console.log("URL: " + response.data.documentURL);
    var json = await downloadSignedURLJsonFile(response.data.documentURL);

    dispatch({
      type: fetchAVData,
      payload: json.data
    });
  } catch (err) {
    console.log("Error invoking fetching AV Data action." + err);
    dispatch({
      type: addError,
      payload: {
        errorCode: TAS_REPORT_FETCH_AV_DATA_FAIL,
        data: err
      }
    });
  }
};

export const generateavreportpdf = (
  selectedstafffunction,
  managernames,
  staffNames,
  avNames,
  costCenters,
  locations
) => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, TAS_REPORT_FETCH_AV_PDF_DATA_FAIL);
    const response = await apiALB(getState().credential.token).post(
      "/generate/av-pdf",
      {
        managernames: managernames,
        executionUser: getState().credential.username,
        departmentname: selectedstafffunction,
        staffNames: staffNames,
        avKeys: avNames,
        costCenters: costCenters,
        locations: locations
      }
    );
    dispatch({ type: generateavpdf, payload: response.data });
    return null;
  } catch (err) {
    return err;
  }
};

export const generateavreportexcel = (
  selectedstafffunction,
  managernames,
  staffNames,
  avNames,
  costCenters,
  locations
) => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, TAS_REPORT_FETCH_AV_EXCEL_DATA_FAIL);
    const response = await apiALB(getState().credential.token).post(
      "/generate/av-excel",
      {
        managernames: managernames,
        executionUser: getState().credential.username,
        departmentname: selectedstafffunction,
        staffNames: staffNames,
        avKeys: avNames,
        costCenters: costCenters,
        locations: locations
      }
    );
    dispatch({ type: generateavexcel, payload: response.data });
    return null;
  } catch (err) {
    return err;
  }
};

export const selectAssignmentVehicle = assignmentvehicle => {
  return {
    type: selectedAssignmentVehicle,
    payload: assignmentvehicle
  };
};

export const fetchLAReportData = (
  departmentname,
  managernames,
  assignmentVehicleKey,
  staffnames,
  costcenters,
  locations
) => async (dispatch, getState) => {
  try {
    let data = JSON.stringify({
      departmentname: departmentname,
      managerNames: managernames,
      assignmentVehicleKey: assignmentVehicleKey,
      staffNames: staffnames,
      costCenters: costcenters,
      locations: locations
    });
    console.log("Json Input: " + data);

    const response = await apiALB(getState().credential.token).post(
      "/generate/la-report",
      data
    );
    dispatch({ type: fetchLAData, payload: response.data });

    dispatch({
      type: laExecutionTime,
      payload: JSON.parse(response.data.queryExecutionTime)
    });
  } catch (err) {
    console.log("Error invoking fetching LA Data action." + err);
    dispatch({
      type: addError,
      payload: {
        errorCode: TAS_REPORT_FETCH_LA_DATA_FAIL,
        data: err
      }
    });
  }
};

export const generatelareportpdf = (
  lareportdata,
  selectedassignmentVehicle,
  lmsDataRefresh
) => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, TAS_REPORT_FETCH_LA_PDF_DATA_FAIL);
    const response = await apiALB(getState().credential.token).post(
      "/generate/la-pdf",
      {
        lmsDataRefresh: lmsDataRefresh,
        executionUser: getState().credential.username,
        avName: selectedassignmentVehicle,
        laData: lareportdata
      }
    );
    dispatch({ type: generatelapdf, payload: response.data });
    return null;
  } catch (err) {
    return err;
  }
};

export const generatelareportexcel = (
  lareportdata,
  selectedassignmentVehicle,
  lmsDataRefresh
) => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, TAS_REPORT_FETCH_LA_EXCEL_DATA_FAIL);
    const response = await apiALB(getState().credential.token).post(
      "/generate/la-excel",
      {
        lmsDataRefresh: lmsDataRefresh,
        executionUser: getState().credential.username,
        avName: selectedassignmentVehicle,
        laData: lareportdata
      }
    );
    dispatch({ type: generatelaexcel, payload: response.data });
    return null;
  } catch (err) {
    return err;
  }
};

export const avManagerAdd = manager => {
  return {
    type: addAVManager,
    payload: manager
  };
};

export const avManagerRemove = manager => {
  return {
    type: removeAVManager,
    payload: manager
  };
};

export const avAdd = av => {
  return {
    type: addAV,
    payload: av
  };
};

export const avRemove = av => {
  return {
    type: removeAV,
    payload: av
  };
};

export const avStaffAdd = staff => {
  return {
    type: addAVStaff,
    payload: staff
  };
};

export const avStaffRemove = staff => {
  return {
    type: removeAVStaff,
    payload: staff
  };
};

export const avLocationAdd = location => {
  return {
    type: addAVLocation,
    payload: location
  };
};

export const avLocationRemove = location => {
  return {
    type: removeAVLocation,
    payload: location
  };
};

export const avCostCenterAdd = costcenter => {
  return {
    type: addCostCenter,
    payload: costcenter
  };
};

export const avCostCenterRemove = costcenter => {
  return {
    type: removeCostCenter,
    payload: costcenter
  };
};

export const avCompletionStatusAdd = completionstatus => {
  return {
    type: addCompletionStatus,
    payload: completionstatus
  };
};

export const avCompletionStatusRemove = completionstatus => {
  return {
    type: removeCompletionStatus,
    payload: completionstatus
  };
};

export const removeSelectedManagers = () => {
  return {
    type: removeAllSelectedManagers,
    payload: []
  };
};

// const api = token => {
//   return axios.create({
//     baseURL: config.webAppConfiguration.tasUrl,
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   });
// };

const apiALB = token => {
  return axios.create({
    baseURL: config.webAppConfiguration.tasalbUrl,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`
    }
  });
};

const clearErrorByDispatch = (dispatch, errorCode) => {
  dispatch({
    type: removeError,
    payload: { errorCode: errorCode }
  });
};
