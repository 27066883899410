import {
  userCredential,
  addError,
  removeError,
  removeAllError
} from "../actions/types";

export const storeCredentialReducer = (credential = null, action) => {
  if (action.type === userCredential) {
    return action.payload;
  }
  return credential;
};

export const errorReducer = (errors = [], action) => {
  if (action.type === addError) {
    return [...errors, action.payload];
  } else if (action.type === removeError) {
    return errors.filter(error => error.errorCode !== action.payload.errorCode);
  } else if (action.type === removeAllError) {
    return [];
  } else {
    return errors;
  }
};
