import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Button } from "semantic-ui-react";
import Prompt from "./Prompt";
import { connect } from "react-redux";
import {
  clearReport,
  removeAllStaff,
  clearSummary,
  setUserMode,
  clearError,
  setSummaryForFilterGenerated,
  clearSummaryForFilter
} from "./actions";
import Report from "./Report/Report";
import "./sas.css";
import queryString from "query-string";
import { FETCH_SUMMARY_FAIL } from "../Common/errorcodes/errorcodes";

class SASReport extends Component {
  state = { loadReportComponent: false, loadPromptComponent: true };

  componentDidMount() {
    const url = queryString.parse(this.props.location.search);
    if (url && url.mode && url.mode.trim() === "self") {
      if (!this.state.loadReportComponent) {
        this.props.setUserMode("self");
        this.setState({
          loadReportComponent: true,
          loadPromptComponent: false
        });
      }
    }
  }

  loadReport() {
    this.props.setSummaryForFilterGenerated(false);
    this.props.clearReport();
    this.props.removeAllStaff();
    this.props.clearSummary();
    this.props.clearSummaryForFilter();
    this.props.clearError(FETCH_SUMMARY_FAIL);
    this.setState({ loadReportComponent: true, loadPromptComponent: false });
  }

  loadPrompt() {
    this.setState({ loadReportComponent: false, loadPromptComponent: true });
  }

  renderPrompt() {
    return (
      <Container>
        <Prompt />
        <div style={{ marginRight: "1vw" }}>
          <Button
            basic
            color="blue"
            className="submit-cancle "
            onClick={() => this.loadReport()}
          >
            Submit
          </Button>
        </div>
      </Container>
    );
  }

  render() {
    const { loadReportComponent, loadPromptComponent } = this.state;

    if (loadReportComponent) {
      return <Report />;
    }
    if (loadPromptComponent) {
      return this.renderPrompt();
    }
  }
}

export default withRouter(
  connect(
    null,
    {
      clearReport,
      removeAllStaff,
      clearSummary,
      setUserMode,
      clearError,
      setSummaryForFilterGenerated,
      clearSummaryForFilter
    }
  )(SASReport)
);
