import React, { Component, Fragment } from "react";
import { List, Segment } from "semantic-ui-react";
import { default as AssignType } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import { connect } from "react-redux";
import { addAssignmentType, removeAssignmentType } from "../actions";
class AssignmentType extends Component {
  onAssignmentTypeChange = (e, assignmentType, id) => {
    // assignmentType = { assignmentType, id };
    if (!e.target.previousSibling.checked) {
      this.props.addAssignmentType(assignmentType);
    } else {
      this.props.removeAssignmentType(assignmentType);
    }
  };

  renderAssignmentTypes() {
    return this.props.assignmentTypes.map(a => (
      <AssignType
        key={a.assignmentTypeId}
        checked={this.props.selectedAssignmentTypes.includes(a.assignmentType)}
        classname=""
        label={a.assignmentType}
        name={`${a.assignmentType}_${a.assignmentTypeId}`}
        value={a.assignmentTypeId}
        handlechange={e =>
          this.onAssignmentTypeChange(e, a.assignmentType, a.assignmentTypeId)
        }
      />
    ));
  }

  render() {
    if (this.props.assignmentTypes) {
      return (
        <Segment raised style={{ minHeight: "27vh" }}>
          <h5>
            <b>Select Type of Assignment</b>
          </h5>
          <List>{this.renderAssignmentTypes()}</List>
        </Segment>
      );
    }
    return <Fragment></Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    selectedAssignmentTypes: state.selectedAssignmentTypes
  };
};
export default connect(
  mapStateToProps,
  { addAssignmentType, removeAssignmentType }
)(AssignmentType);
