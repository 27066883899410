import LoadingOverlay from "react-loading-overlay";
import PulseLoader from "react-spinners/PulseLoader";
import React, { Component } from "react";
import { css } from "@emotion/core";

const withLoader = WrappedComponent => {
  class HOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isActive: false
      };

      this.showLoader = this.showLoader.bind(this);
      this.hideLoader = this.hideLoader.bind(this);
    }
    showLoader() {
      this.setState({ isActive: true });
    }

    hideLoader() {
      this.setState({ isActive: false });
    }
    render() {
      const { isActive } = this.state;
      const override = css`
        position: relative;
        width: 13vw;
        margin-top: -1px;
      `;
      return (
        <LoadingOverlay
          active={isActive}
          spinner={
            <React.Fragment>
              <div
                style={{
                  float: "left",
                  width: "0px",
                  fontWeight:"normal",
                  fontSize: "14px",
                  paddingRight:"5px"
                }}
              >
                Generating
              </div>
              <div>
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#FFFFFFFF"}
                />
              </div>
            </React.Fragment>
          }
          styles={{
            wrapper: {
              //width: '100%'
            }
          }}
        >
          <WrappedComponent {...this.props }
            showLoader={this.showLoader}
            hideLoader={this.hideLoader}
          />
        </LoadingOverlay>
      );
    }
  }

  return HOC;
};

export default withLoader;
