import React, { Component, Fragment } from "react";
import { Grid } from "semantic-ui-react";
import EmployeeStatus from "./EmployeeStatus";
import EmployeeType from "./EmployeeType";
import Certification from "./Certification";
import AssignmentType from "./AssignmentType";
import RegulatoryBody from "./RegulatoryBody";
import AssignmentStatus from "./AssignmentStatus";
import ReportView from "./ReportView";
import { fetchLov } from "../actions";
import { connect } from "react-redux";
import Progressbar from "../../Common/controls/Progressbar/Progressbar";
import { FETCH_LOV_FAIL } from "../../Common/errorcodes/errorcodes";
import ErrorMessage from "../../Common/controls/Message/ErrorMessage";

class Lov extends Component {
  componentDidMount() {
    const { credential } = this.props;
    if (credential) {
      this.props.fetchLov();
    }
  }

  componentDidUpdate() {
    const { credential, hasError } = this.props;
    if (credential && !this.props.lovs && !hasError) {
      this.props.fetchLov();
    }
  }
  render() {
    const { lovs, hasError } = this.props;
    if (hasError) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching filters"
          errorDescription={hasError}
        />
      );
    }
    if (lovs != null) {
      const empStatuses = lovs.employeeStatus;
      const assignmentStatuses = lovs.assignmentStatus;
      const assignmentTypes = lovs.assignmentTypes;
      const certifications = lovs.certifications;
      const empTypes = lovs.employeeTypes;
      const regBodies = lovs.regulatoryBodies;
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <EmployeeStatus empStatuses={empStatuses} />
            </Grid.Column>
            <Grid.Column width={4}>
              <div id="emp-type">
                <EmployeeType empTypes={empTypes} />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <Certification certifications={certifications} />
            </Grid.Column>
            <Grid.Column width={4}>
              <AssignmentType assignmentTypes={assignmentTypes} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <RegulatoryBody regBodies={regBodies} />
            </Grid.Column>
            <Grid.Column width={4}>
              <div id="assign-status">
                <AssignmentStatus assignmentStatuses={assignmentStatuses} />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <ReportView />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return (
      <Fragment>
        <Progressbar Message="Filter" />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    credential: state.credential,
    lovs: state.lovs,
    hasError:
      state.errors &&
      state.errors.some(code => code.errorCode === FETCH_LOV_FAIL) &&
      state.errors.filter(code => code.errorCode === FETCH_LOV_FAIL)[0].data
  };
};

export default connect(
  mapStateToProps,
  { fetchLov }
)(Lov);
