import React, { Component, Fragment } from "react";
import { List, Segment } from "semantic-ui-react";
import { default as CertType } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import { connect } from "react-redux";
import { addCertification, removeCertification } from "../actions";
class Certification extends Component {
  /* onCertificationChange = (e, cert, id) => {
    id = id === 1 ? "Y" : "N";
    cert = { cert, id };
    if (!e.target.previousSibling.checked) {
      this.props.addCertification(id);
    } else {
      this.props.removeCertification(id);
    }
  }; */
  onCertificationChange = (e, id) => {
    if (!e.target.previousSibling.checked) {
      this.props.addCertification(id);
    } else {
      this.props.removeCertification(id);
    }
  };

  renderCertifications() {
    const { selectedCertifications, certifications } = this.props;
    return certifications.map(c => (
      /*    <CertType
        key={c.certBodyId}
        checked={
          selectedCertifications &&
          ((selectedCertifications.includes("Y") && c.certBodyId === 1) ||
            (selectedCertifications.includes("N") && c.certBodyId === 2))
        }
        classname=""
        label={c.certBody}
        name={`${c.certBody}_${c.certBodyId}`}
        value={c.BodyId}
        handlechange={e =>
          this.onCertificationChange(e, c.certBody, c.certBodyId)
        }
      /> */
      <CertType
        key={c.certBodyId}
        checked={selectedCertifications.includes(c.certBodyId)}
        classname=""
        label={c.certBody==='Y'?'Include only Certifications':'Exclude all Certifications'}
        name={`${c.certBody}_${c.certBodyId}`}
        value={c.BodyId}
        handlechange={e => this.onCertificationChange(e, c.certBodyId)}
      />
    ));
  }

  render() {
    if (this.props.certifications) {
      return (
        <Segment raised style={{ minHeight: "27vh" }}>
          <h5>
            <b>Certification</b>
          </h5>
          <List>{this.renderCertifications()}</List>
        </Segment>
      );
    }
    return <Fragment></Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    selectedCertifications: state.selectedCertifications
  };
};
export default connect(
  mapStateToProps,
  { addCertification, removeCertification }
)(Certification);
