import React, { Component } from "react";
import { List, Checkbox } from "semantic-ui-react";
import {
  fetchLearners,
  addLearner,
  removeLearner,
  removeAllLearners
} from "../actions";
import { connect } from "react-redux";
import { default as Learner } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import Progressbar from "../../Common/controls/Progressbar/Progressbar";
import "../sas.css";
import { FETCH_LEARNERS_FAIL } from "../../Common/errorcodes/errorcodes";
import Message from "../../Common/controls/Message/Message";
import ErrorMessage from "../../Common/controls/Message/ErrorMessage";

class Learners extends Component {
  addAllLearners() {
    const { employees, selectedLearners } = this.props;
    for (let i = 0; i < employees.length; i++) {
      if (!selectedLearners.includes(employees[i].empid))
        this.props.addLearner(employees[i].empid);
    }
  }

  onCheckAllChange(e) {
    if (!e.target.previousSibling.checked) {
      this.addAllLearners();
    } else {
      this.props.removeAllLearners();
    }
  }

  onLearnerChange = (e, id) => {
    if (!e.target.previousSibling.checked) {
      this.props.addLearner(id);
    } else {
      this.props.removeLearner(id);
    }
  };

  componentDidMount() {
    const { credential } = this.props;
    if (credential) {
      this.props.fetchLearners(credential.loginId);
    }
  }

  renderLearners() {
    const { selectedLearners, employees } = this.props;
    return employees.map(employee => (
      <Learner
        key={employee.empid}
        checked={selectedLearners.some(id => id === employee.empid)}
        classname="list_style"
        label={`${employee.lastName},${employee.firstName}`}
        name={`${employee.firstName}_${employee.lastName}_${employee.empid}`}
        value={employee.empid}
        handlechange={e => this.onLearnerChange(e, employee.empid)}
      />
    ));
  }

  render() {
    const { employees, selectedLearners, errors } = this.props;
    if (errors && errors.find(code => code.errorCode === FETCH_LEARNERS_FAIL)) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching learners"
          errorDescription={
            errors.filter(code => code.errorCode === FETCH_LEARNERS_FAIL)[0]
              .data
          }
        />
      );
    }
    if (employees) {
      if (employees.length > 0) {
        return (
          <React.Fragment>
            <List.Item>
              <b>
                Select one or more learners from the list below to include on
                the report .
              </b>
            </List.Item>
            <Checkbox
              label="Select All"
              onChange={e => this.onCheckAllChange(e)}
              checked={selectedLearners.length === employees.length}
            />
            <List horizontal className="scroll" style={{ height: "20vh" }}>
              {this.renderLearners()}
            </List>
          </React.Fragment>
        );
      }

      return <Message display="No records found" />;
    }
    return (
      <React.Fragment>
        <Progressbar Message="Learners" />
      </React.Fragment>
    );
  }

  /*  componentDidUpdate() {
    const { credential, employees } = this.props;
    if (credential && !employees) {
      this.props.fetchLearners(credential.loginId); //to:do send from credential
    }
  } */
}

const mapStateToProps = state => {
  return {
    employees: state.learners,
    credential: state.credential,
    selectedLearners: state.selectedLearners,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  { fetchLearners, addLearner, removeLearner, removeAllLearners }
)(Learners);
