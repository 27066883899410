export const FETCH_REPORT_FAIL = 1;
export const FETCH_LEARNERS_FAIL = 2;
export const FETCH_ASSIGNMENTS_FAIL = 3;
export const FETCH_ACTIVITIES_FAIL = 4;
export const FETCH_LOV_FAIL = 5;
export const FETCH_DRILLDOWN_REPORT_FAIL = 6;
export const TAS_REPORT_FETCH_STAFF_FUNCTIONS_FAIL = 6;
export const TAS_REPORT_FETCH_STAFF_MANAGERS_FAIL = 7;
export const TAS_REPORT_FETCH_AV_DATA_FAIL = 8;
export const TAS_REPORT_FETCH_LA_DATA_FAIL = 9;
export const TAS_REPORT_FETCH_AV_PDF_DATA_FAIL = 10;
export const TAS_REPORT_FETCH_AV_EXCEL_DATA_FAIL = 11;
export const TAS_REPORT_FETCH_LA_PDF_DATA_FAIL = 12;
export const TAS_REPORT_FETCH_LA_EXCEL_DATA_FAIL = 13;
export const GENERATE_PDF_FAIL = 14;
export const GENERATE_EXCEL_FAIL = 15;
export const FETCH_SUMMARY_FAIL = 16;
export const FETCH_EMPKEY_FAIL = 17;
export const FETCH_ACTKEY_FAIL = 18;