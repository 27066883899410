import React, { Component } from "react";
import {
  fetchDepartments,
  selectStaffFunction,
  fetchManagers,
  removeSelectedManagers,
  ManagerSelect
} from "./actions";
import { connect } from "react-redux";
import { List, Radio, Label } from "semantic-ui-react";
import Progressbar from "../Common/controls/Progressbar/Progressbar";
import { TAS_REPORT_FETCH_STAFF_FUNCTIONS_FAIL } from "../Common/errorcodes/errorcodes";
import Message from "../Common/controls/Message/Message";
import ErrorMessage from "../Common/controls/Message/ErrorMessage";

class StaffFunctions extends Component {
  async componentDidMount() {
    const { credential, departments } = this.props;
    if (credential && !departments) {
      console.log("Logged-in User: " + this.props.credential.staffId);
      await this.props.fetchDepartments(this.props.credential.staffId);
      // await this.props.fetchDepartments("SureshKumar Sekaran");
      //await this.props.fetchDepartments("Surya Chirla");

      if (!departments && !this.props.selectedStaffFunction) {
        await this.props.selectStaffFunction(
          this.props.departments.defaultfunction
        );

        if (this.props.departments.loggedInManagerName) {
          const manager = { name: this.props.departments.loggedInManagerName };
          this.props.ManagerSelect(manager);
        }
      }

      if (this.props.selectedStaffFunction) {
        this.props.fetchManagers(this.props.departments.defaultfunction);
      }
    }
  }
  async componentDidUpdate() {
    if (!this.props.departments) {
      console.log("Logged-in User: " + this.props.credential.staffId);
      await this.props.fetchDepartments(this.props.credential.staffId);
      // await this.props.fetchDepartments("SureshKumar Sekaran");
      //await this.props.fetchDepartments("Surya Chirla");

      if (!this.props.selectedStaffFunction && this.props.departments) {
        await this.props.selectStaffFunction(
          this.props.departments.defaultfunction
        );

        if (this.props.departments.loggedInManagerName) {
          const manager = { name: this.props.departments.loggedInManagerName };
          this.props.ManagerSelect(manager);
        }
      }

      if (this.props.selectedStaffFunction) {
        this.props.fetchManagers(this.props.departments.defaultfunction);
      }
    }
  }
  onDepartmentChange = (e, { value }) => {
    this.props.selectStaffFunction(value);
    this.props.fetchManagers(value);
    this.props.removeSelectedManagers();
  };

  render() {
    const { departments, managers, errors } = this.props;

    if (
      errors &&
      errors.find(
        code => code.errorCode === TAS_REPORT_FETCH_STAFF_FUNCTIONS_FAIL
      ) &&
      errors.filter(
        code => code.errorCode === TAS_REPORT_FETCH_STAFF_FUNCTIONS_FAIL
      )[0].data
    ) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching staff functions"
          errorDescription={
            errors.filter(
              code => code.errorCode === TAS_REPORT_FETCH_STAFF_FUNCTIONS_FAIL
            )[0].data
          }
        />
      );
    }
    var managersCount = 0;
    if (managers) {
      managersCount = Object.keys(managers).length;
    }

    if (departments) {
      if (departments.data && departments.data.length > 0) {
        return (
          <div>
            <List horizontal className="listHeight">
              {departments.data.map((option, i) => {
                return (
                  <List.Item className="opsList" key={option.departmentname}>
                    <Radio
                      label={option.departmentname}
                      onChange={this.onDepartmentChange}
                      name="departmentRadioGroup"
                      value={option.departmentname}
                      checked={
                        this.props.selectedStaffFunction ===
                        option.departmentname
                      }
                    />
                  </List.Item>
                );
              })}
            </List>

            <Label>
              Selected Function: {this.props.selectedStaffFunction}, Fetched
              Managers:{managersCount}
            </Label>
          </div>
        );
      }

      return <Message display="No records found" />;
    }
    return (
      <div className="loader">
        <Progressbar Message="Staff Functions" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    departments: state.staffFunctions,
    credential: state.credential,
    selectedStaffFunction: state.selectedStaffFunction,
    managers: state.staffManagers,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  {
    fetchDepartments,
    selectStaffFunction,
    fetchManagers,
    removeSelectedManagers,
    ManagerSelect
  }
)(StaffFunctions);
