import styles from "./Home.module.css";
import Dashboard from "../DashBoard/DashBoard";
import React from "react";
const Home = () => {
  return (
    <div className="home">
      <div className={styles.lms_text}>
        <p>LMS Custom Reports</p>
      </div>
      <Dashboard />
    </div>
  );
};
export default Home;

/*
try {
        const accessToken = await this.props.auth.getAccessToken();
        
        const response = await fetch(config.resourceServer.saslovsUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,  
            'Access-Control-Allow-Origin' : '*',          
          },
        });

        if (response.status !== 200) {
          this.setState({ failed: true });
          return;
        }

        let index = 0;
        const data1 = await response.json();

        
        this.setState({ data: data1, failed: false });
      } catch (err) {
        this.setState({ failed: true });
        console.error(err);
      }
 */
