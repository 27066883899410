import {
  fetchEmpKey,
  selectedReportType,
  selectedActivityType,
  fetchEmployees,
  learnerAdd,
  learnerRemove,
  activityAdd,
  activityRemove,
  fetchActivitiesType,
  fetchLovType,
  addEmpStatus,
  removeEmpStatus,
  addEmpType,
  removeEmpType,
  addAssignType,
  removeAssignType,
  addRegBody,
  removeRegBody,
  addCert,
  removeCert,
  addAssignStatus,
  removeAssignStatus,
  fetchAllAssignments,
  assignmentAdd,
  assignmentRemove,
  removeAllActivities,
  removeAllAssignments,
  summary,
  report,
  drilldownType,
  generateExcel,
  generatePDF,
  clearReportData,
  reportViewType,
  addStaffType,
  removeStaffType,
  removeAllStaffType,
  resetSasType,
  removeAllLearnersType,
  clearSummaryData,
  userMode,
  isSummaryReGeneratedType,
  summaryForFilter,
  removeSummaryForFilter,
  thresholdCrossed,
  fetchActivityKeyType,
  summaryExecutionTime,
  drilldownExecutionTime,
  reportExecutionTime
} from "../actions/types";

export const drilldownExecutionTimeReducer = (time = null, action) => {
  if (action.type === drilldownExecutionTime) {
    return action.payload;
  }
  return time;
};

export const reportExecutionTimeReducer = (time = null, action) => {
  if (action.type === reportExecutionTime) {
    return action.payload;
  }
  return time;
};

export const thresholdHitReducer = (crossed = false, action) => {
  if (action.type === thresholdCrossed) {
    return action.payload;
  }
  return crossed;
};

export const summaryForFilterReducer = (summary = null, action) => {
  if (action.type === summaryForFilter || action.type === removeSummaryForFilter) {
    return action.payload;
  }
  return summary;
};

export const isSummaryGeneratedReducer = (isGenerated = false, action) => {
  if (action.type === isSummaryReGeneratedType) {
    return action.payload;
  }
  return isGenerated;
};

export const setUserModeReducer = (mode = null, action) => {
  if (action.type === userMode) {
    return action.payload;
  }
  return mode;
};

export const selectedReportViewReducer = (reportView = null, action) => {
  if (action.type === reportViewType) {
    return action.payload;
  }
  return reportView;
};

export const selectedReportTypeReducer = (reportType = null, action) => {
  if (action.type === selectedReportType) {
    return action.payload;
  }
  return reportType;
};

export const selectedActivityTypeReducer = (activityType = null, action) => {
  if (action.type === selectedActivityType) {
    return action.payload;
  }
  return activityType;
};

export const learnersReducer = (learners = null, action) => {
  if (action.type === fetchEmployees) {
    return action.payload;
  }
  return learners;
};

export const fetchPDFReducer = (PDFData = null, action) => {
  if (action.type === generatePDF) {
    return action.payload;
  }
  return PDFData;
};

export const fetchExcelReducer = (excelData = null, action) => {
  if (action.type === generateExcel) {
    return action.payload;
  }
  return excelData;
};

export const fetchEmpKeyReducer = (empkey = null, action) => {
  if (action.type === fetchEmpKey) {
    return action.payload.empkey;
  }
  return empkey;
};

export const setSummaryExecutionTimeReducer = (time = null, action) => {
  if (action.type === summaryExecutionTime) {
    return action.payload;
  }
  return time;
};

export const fetchActivityKeyReducer = (actkey = null, action) => {
  if (action.type === fetchActivityKeyType) {
    return action.payload.activityKey;
  }
  return actkey;
};

export const fetchedReducer = (fetchedStatuses = [], action) => {
  if (action.type === "L_F") {
    fetchedStatuses = fetchedStatuses.filter(
      x => x.method !== action.payload.method
    );

    return [...fetchedStatuses, action.payload];
  }
  return fetchedStatuses;
};

export const learnerReducer = (selectedlearners = [], action) => {
  if (action.type === learnerAdd) {
    return [...selectedlearners, action.payload];
  } else if (action.type === learnerRemove) {
    return selectedlearners.filter(learner => learner !== action.payload);
  } else if (action.type === removeAllLearnersType) {
    return action.payload;
  } else {
    return selectedlearners;
  }
};

export const staffReducer = (selectedStaff = [], action) => {
  if (action.type === addStaffType) {
    return [...selectedStaff, action.payload];
  } else if (action.type === removeStaffType) {
    return selectedStaff.filter(staff => staff !== action.payload);
  } else if (action.type === removeAllStaffType) {
    return action.payload;
  } else if (action.type === resetSasType) {
    return [];
  } else {
    return selectedStaff;
  }
};

export const activitiesReducer = (activities = null, action) => {
  if (action.type === fetchActivitiesType) {
    return action.payload;
  }
  return activities;
};

export const selectedActivitiesReducer = (selectedActivities = [], action) => {
  if (action.type === activityAdd) {
    return [...selectedActivities, action.payload];
  } else if (action.type === activityRemove) {
    return selectedActivities.filter(activity => activity !== action.payload);
  } else if (action.type === removeAllActivities) {
    return [];
  } else {
    return selectedActivities;
  }
};

export const fetchLovReducer = (lov = null, action) => {
  if (action.type === fetchLovType) {
    return action.payload;
  }
  return lov;
};

export const selectedEmpStatusReducer = (selectedEmpStatus = [], action) => {
  if (action.type === addEmpStatus) {
    return [...selectedEmpStatus, action.payload];
  } else if (action.type === removeEmpStatus) {
    return selectedEmpStatus.filter(status => status !== action.payload);
  } else {
    return selectedEmpStatus;
  }
};

export const selectedEmpTypesReducer = (selectedEmpTypes = [], action) => {
  if (action.type === addEmpType) {
    return [...selectedEmpTypes, action.payload];
  } else if (action.type === removeEmpType) {
    return selectedEmpTypes.filter(empType => empType !== action.payload);
  } else {
    return selectedEmpTypes;
  }
};

export const selectedRegulatoryBodiesReducer = (
  selectedRegulatoryBodies = [],
  action
) => {
  if (action.type === addRegBody) {
    return [...selectedRegulatoryBodies, action.payload];
  } else if (action.type === removeRegBody) {
    return selectedRegulatoryBodies.filter(
      regBody => regBody !== action.payload
    );
  } else {
    return selectedRegulatoryBodies;
  }
};

export const selectedCertificationsReducer = (
  selectedCertifications = [],
  action
) => {
  if (action.type === addCert) {
    return [...selectedCertifications, action.payload];
  } else if (action.type === removeCert) {
    return selectedCertifications.filter(cert => cert !== action.payload);
  } else {
    return selectedCertifications;
  }
};

export const selectedAssignmentTypesReducer = (
  selectedAssignments = [],
  action
) => {
  if (action.type === addAssignType) {
    return [...selectedAssignments, action.payload];
  } else if (action.type === removeAssignType) {
    return selectedAssignments.filter(
      assignment => assignment !== action.payload
    );
  } else {
    return selectedAssignments;
  }
};

export const selectedAssignmentStatusReducer = (
  selectedAssignmentStatuses = [],
  action
) => {
  if (action.type === addAssignStatus) {
    return [...selectedAssignmentStatuses, action.payload];
  } else if (action.type === removeAssignStatus) {
    return selectedAssignmentStatuses.filter(
      assignmentStatus => assignmentStatus !== action.payload
    );
  } else {
    return selectedAssignmentStatuses;
  }
};

export const assignmentsReducer = (assignments = null, action) => {
  if (action.type === fetchAllAssignments) {
    return action.payload;
  }
  return assignments;
};

export const summaryReducer = (summaryData = null, action) => {
  if (action.type === clearSummaryData) return null;
  if (action.type === summary) {
    return action.payload;
  }
  return summaryData;
};

export const reportReducer = (reportData = null, action) => {
  if (action.type === clearReportData) return null;
  if (action.type === report) {
    return action.payload;
  }
  return reportData;
};

export const drillDownReducer = (drillDownData = null, action) => {
  if (action.type === drilldownType) {
    return action.payload;
  }
  return drillDownData;
};

export const selectedAssignmentReducer = (selectedAssignments = [], action) => {
  if (action.type === assignmentAdd) {
    return [...selectedAssignments, action.payload];
  } else if (action.type === assignmentRemove) {
    return selectedAssignments.filter(
      assignment => assignment !== action.payload
    );
  } else if (action.type === removeAllAssignments) {
    return [];
  } else {
    return selectedAssignments;
  }
};
