import React from "react";
const PageNotFound = () => {
  return (
    <div
      style={{
        height: "200px",
        width: "400px",
        top: "50%",
        left: "45%",
        marginLeft: "-100px",
        marginTop: "-100px",
        position: "fixed"
      }}
    >
      <h1>Oops! Page not found.</h1>
    </div>
  );
};

export default PageNotFound;
