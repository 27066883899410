export const selectedReportType = "REPORT_SELECTED";
export const selectedActivityType = "ACTIVITY_SELECTED";
export const fetchEmployees = "FETCH_EMPLOYEES";
export const learnerAdd = "ADD_LEARNER";
export const learnerRemove = "Remove_LEARNER";
export const fetchActivitiesType = "FETCH_ACTIVITIES";
export const activityAdd = "ADD_ACTIVITY";
export const activityRemove = "Remove_ACTIVITY";
export const removeAllActivities = "REMOVE_ALL_ACTIVITIES";
export const fetchLovType = "FETCH_LOV";
export const addEmpStatus = "ADD_EMP_STATUS";
export const removeEmpStatus = "REMOVE_EMP_STATUS";
export const addEmpType = "ADD_EMP_TYPE";
export const removeEmpType = "REMOVE_EMP_TYPE";
export const addAssignType = "ADD_ASSIGNMENT_TYPE";
export const removeAssignType = "REMOVE_ASSIGNMENT_TYPE";
export const addAssignStatus = "ADD_ASSIGNMENTSTATUS";
export const removeAssignStatus = "REMOVE_ASSIGNMENTSTATUS";
export const addRegBody = "ADD_REGBODY";
export const removeRegBody = "REMOVE_REGBODY";
export const addCert = "ADD_CERTIFICATION";
export const removeCert = "REMOVE_CERTIFICATION";
export const fetchAllAssignments = "FETCH_ASSIGNMENTS";
export const assignmentAdd = "ADD_ASSIGNMENT";
export const assignmentRemove = "Remove_ASSIGNMENT";
export const removeAllAssignments = "REMOVE_ALL_ASSIGNMENT";
export const summary = "SUMMARY";
export const report = "REPORT";
export const drilldownType = "DRILLDOWN";
export const generatePDF = "GENERATE_SAS_PDF";
export const generateExcel = "GENERATE_SAS_EXCEL";
export const clearReportData = "clearReportData";
export const clearSummaryData = "clearSummaryData";
export const reportViewType = "SELECT_REPORT_VIEW";
export const addStaffType = "ADD_STAFF";
export const removeStaffType = "REMOVE_STAFF";
export const removeAllStaffType = "REMOVE_ALL_STAFF";
export const resetSasType = "RESET_SAS";
export const removeAllLearnersType = "REMOVE_ALL_LEARNERS";
export const removeAllAssignmentsType = "REMOVE_ALL_ASSIGNMENTS";
export const removeAllActivitiesType = "REMOVE_ALL_ACTIVITIES";
export const userMode = "USER_MODE";
export const isSummaryReGeneratedType = "IS_SUMMARY_REGENERATED";
export const summaryForFilter = "SUMMARY_FOR_FILTER";
export const thresholdCrossed = "THRESHOLD";
export const fetchEmpKey = "FETCH_EMP_KEY";
export const fetchActivityKeyType = "FETCH_ACTIVITY_KEY";
export const summaryExecutionTime = "SUMMARY_EXECUTION_TIME";
export const drilldownExecutionTime = "DRILLDOWN_EXECUTION_TIME";
export const reportExecutionTime = "REPORT_EXECUTION_TIME";
export const removeSummaryForFilter = "CLEAR_SUMMARY_FOR_FILTER";