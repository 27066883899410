import React, { Component } from "react";
import { List, Checkbox } from "semantic-ui-react";
import {
  fetchActivities,
  addActivity,
  removeActivity,
  removeActivities
} from "../actions";
import { connect } from "react-redux";
import { default as Activity } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import Progressbar from "../../Common/controls/Progressbar/Progressbar";
import "../sas.css";
import "./Activities.css";
import { FETCH_ACTIVITIES_FAIL } from "../../Common/errorcodes/errorcodes";
import Message from "../../Common/controls/Message/Message";
import ErrorMessage from "../../Common/controls/Message/ErrorMessage";

class Activities extends Component {
  addAllActivities() {
    const { activities, selectedActivities } = this.props;
    for (let i = 0; i < activities.length; i++) {
      if (!selectedActivities.includes(activities[i].activityid))
        this.props.addActivity(activities[i].activityid);
    }
  }

  onCheckAllChange(e) {
    if (!e.target.previousSibling.checked) {
      this.addAllActivities();
    } else {
      this.props.removeActivities();
    }
  }
  onActivityChange = (e, id) => {
    if (!e.target.previousSibling.checked) {
      this.props.addActivity(id);
    } else {
      this.props.removeActivity(id);
    }
  };

  componentDidMount() {
    const { credential } = this.props;
    if (credential) {
      this.props.fetchActivities(credential.loginId);
    }
  }

  renderActivities() {
    const { activities, selectedActivities } = this.props;
    return activities.map(act => (
      <Activity
        key={act.activityid}
        checked={selectedActivities.some(a => a === act.activityid)}
        classname="sas_assignment_list_style"
        label={act.activity}
        name={`${act.activity}_${act.activityid}`}
        value={act.activityid}
        handlechange={e => this.onActivityChange(e, act.activityid)}
      />
    ));
  }

  render() {
    const { activities, errors, selectedActivities } = this.props;
    if (
      errors &&
      errors.find(code => code.errorCode === FETCH_ACTIVITIES_FAIL)
    ) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching activities"
          errorDescription={
            errors.filter(code => code.errorCode === FETCH_ACTIVITIES_FAIL)[0]
              .data
          }
        />
      );
    }
    if (activities != null) {
      if (activities.length > 0) {
        return (
          <React.Fragment>
            <List.Item>
              <b>
                Select one or more learning activities from the list below to
                include on the report.
              </b>
            </List.Item>
            <Checkbox
              label="Select All"
              onChange={e => this.onCheckAllChange(e)}
              checked={selectedActivities.length === activities.length}
            />
            <List horizontal className="learningActivity_scroll">
              {this.renderActivities()}
            </List>
          </React.Fragment>
        );
      }

      return <Message display="No records found" />;
    }
    return (
      <React.Fragment>
        <Progressbar Message="Activities" />
      </React.Fragment>
    );
  }

  /* componentDidUpdate() {
    const { credential, errors } = this.props;
    const hasError =
      errors && errors.find(code => code === FETCH_ACTIVITIES_FAIL);
    if (!this.props.activities && !hasError) {
      this.props.fetchActivities(credential.loginId);
    }
  } */
}

const mapStateToProps = state => {
  return {
    activities: state.activities,
    credential: state.credential,
    selectedActivities: state.selectedActivities,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  { fetchActivities, addActivity, removeActivity, removeActivities }
)(Activities);
