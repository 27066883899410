import React, { Component } from "react";
import { Accordion, Icon, Container } from "semantic-ui-react";
import { connect } from "react-redux";
import ReportType from "./ReportType";
import ActivityType from "./ActivityType";
import Learners from "./Learners/Learners";
import Activities from "./Activities/Activities";
import Assignments from "./Assignments/Assignments";
import Lov from "./Lov/Lov";
import "./sas.css";
class Prompt extends Component {
  state = { activeIndex: 0, openSecondPane: true };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ openSecondPane: nextProps.canOpenSecondTab });
  }

  componentDidUpdate() {
    if (this.state.openSecondPane) {
      this.setState({ activeIndex: 1 ,openSecondPane:false});
    }
  }

  renderLearners() {
    if (this.props.selectedReportType === "specific_learners") {
      return <Learners />;
    }
    return "";
  }

  renderActivitiesOrAssignments() {
    if (this.props.selectedActivityType === "learning_activities") {
      return <Activities />;
    } else if (this.props.selectedActivityType === "assignments") {
      return <Assignments />;
    } else {
      return "";
    }
  }

  getTitle() {
    const { selectedActivityType, selectedReportType } = this.props;
    let title = "";
    if (
      selectedReportType === "specific_learners" &&
      selectedActivityType === "assignments"
    ) {
      title = "Select Student and Assignments";
    } else if (
      selectedReportType === "specific_learners" &&
      selectedActivityType === "learning_activities"
    ) {
      title = "Select Student and Activities";
    } else if (
      selectedReportType !== "specific_learners" &&
      selectedActivityType === "learning_activities"
    ) {
      title = "Select Activities";
    } else if (
      selectedReportType !== "specific_learners" &&
      selectedActivityType === "assignments"
    ) {
      title = "Select Assignments";
    } else {
      title = "Select Student";
    }

    return title;
  }

  renderPrompt = () => {
    const { activeIndex } = this.state;
    const { selectedActivityType, selectedReportType } = this.props;
    return (
      <Container>
        <Accordion className="accordion_padding">
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
            className="bgcolor"
          >
            <Icon name="angle down" className="icon_style" />
            <p className="titlestyle"> Select Report and Activity Type</p>
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 0}
            className="content-style"
          >
            <ReportType />
            <hr style={{ margin: "0" }} />
            <ActivityType />
          </Accordion.Content>
          {(selectedActivityType !== "all" ||
            selectedReportType !== "direct") &&
            (selectedActivityType !== "all" ||
              selectedReportType !== "direct_indirect") && (
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                  className="bgcolor"
                >
                  <Icon name="angle down" className="icon_style" />
                  <p className="titlestyle">{this.getTitle()}</p>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 1}
                  className="content_style"
                >
                  {this.renderLearners()}
                  <hr style={{ margin: "0" }} />
                  {this.renderActivitiesOrAssignments()}
                </Accordion.Content>
              </React.Fragment>
            )}
          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={this.handleClick}
            className="bgcolor"
          >
            <Icon name="angle down" className="icon_style" />
            <p className="titlestyle"> Filter Data</p>
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 2}
            className="content_style"
          >
            <Lov />
          </Accordion.Content>
        </Accordion>
      </Container>
    );
  };

  render() {
    return this.renderPrompt();
  }
}
const mapStateToProps = state => {
  return {
    selectedActivityType: state.selectedActivityType,
    selectedReportType: state.selectedReportType,
    isSelfMode: state.selectedUserMode==='self',
    canOpenSecondTab:
      state.selectedActivityType !== "all"
        ? true
        : state.selectedReportType === "specific_learners"
        ? true
        : false
  };
};
export default connect(mapStateToProps)(Prompt);
