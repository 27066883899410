import _ from "lodash";
import React, { Component } from "react";
import { Search } from "semantic-ui-react";
import { ManagerSelect } from "./actions";
import { connect } from "react-redux";
import Progressbar from "../Common/controls/Progressbar/Progressbar";
import { TAS_REPORT_FETCH_STAFF_MANAGERS_FAIL } from "../Common/errorcodes/errorcodes";
import Message from "../Common/controls/Message/Message";
import ErrorMessage from "../Common/controls/Message/ErrorMessage";

const initialState = { isLoading: false, results: [], value: "" };

class StaffManagerSearch extends Component {
  state = initialState;

  handleResultSelect = (e, { result }) => {
    const manager = { name: result.title };
    this.props.ManagerSelect(manager);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState);

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.title);
      const source = _.map(this.props.managers, (m, index) => ({
        title: m.managername,
        key: index
      }));

      this.setState({
        isLoading: false,
        results: _.filter(source, isMatch)
      });
    }, 300);
  };

  render() {
    const { isLoading, value, results, errors } = this.state;
    const { managers } = this.props;

    if (
      errors &&
      errors.find(
        code => code.errorCode === TAS_REPORT_FETCH_STAFF_MANAGERS_FAIL
      ) &&
      errors.filter(
        code => code.errorCode === TAS_REPORT_FETCH_STAFF_MANAGERS_FAIL
      )[0].data
    ) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching staff managers"
          errorDescription={
            errors.filter(
              code => code.errorCode === TAS_REPORT_FETCH_STAFF_MANAGERS_FAIL
            )[0].data
          }
        />
      );
    }

    if (managers) {
      if (managers.length > 0) {
        return (
          <Search
            className="rightAlign"
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true
            })}
            results={results}
            value={value}
          />
        );
      }

      return (
        <div>
          <Message display="No records found" />
        </div>
      );
    }

    return (
      <div className="loader">
        <Progressbar Message="Managers" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    managers: state.staffManagers,
    credential: state.credential,
    selectedManagers: state.selectedManagers,
    errors: state.errors,
    selectedStaffFunction: state.selectedStaffFunction
  };
};

export default connect(
  mapStateToProps,
  { ManagerSelect }
)(StaffManagerSearch);
