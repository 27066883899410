import React, { Component, Fragment } from "react";
import { Table, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { fetchReport, sortReport } from "../actions";
import { connect } from "react-redux";
import { FETCH_REPORT_FAIL } from "../../Common/errorcodes/errorcodes";
import Message from "../../Common/controls/Message/Message";
import ErrorMessage from "../../Common/controls/Message/ErrorMessage";
import Progressbar from "../../Common/controls/Progressbar/Progressbar";
import { calculateDaysOverdueDayPastDueDate } from "../../../helpers";
import "./Report.css";
// import ExecutionTime from "../../Common/controls/ExecutionTime/ExecutionTime";
class DetailedReport extends Component {
  state = {
    direction: null,
    column: null
  };

  handleSort = clickedColumn => {
    const { reports } = this.props;
    const { column, direction } = this.state;

    if (column !== clickedColumn) {
      this.props.sortReport(reports, clickedColumn, "ascending");
      this.setState({
        column: clickedColumn,
        direction: "ascending"
      });
    } else {
      const currentDirection =
        direction === "ascending" ? "descending" : "ascending";
      this.props.sortReport(reports, clickedColumn, currentDirection);
      this.setState({
        direction: currentDirection
      });
    }
  };

  navigateToDrillDown(event, rootSrcActivityPK, workForceId, key) {
    event.preventDefault();
    const url = event.target.href;
    const data = {
      rootSrcActivityPK: rootSrcActivityPK,
      workForceId: workForceId
    };
    window.localStorage.setItem(key, JSON.stringify(data));

    window.open(url, "_newtab");
  }

  componentDidMount() {
    //  this.props.handleReportLoad(false);
    const { credential, hasError, reports, isThresholdHit } = this.props;
    if (credential && !reports && !hasError && !isThresholdHit) {
      this.props.fetchReport();
    }
  }

  componentDidUpdate() {
    const { credential, hasError, reports, isThresholdHit } = this.props;
    if (credential && !reports && !hasError && !isThresholdHit) {
      this.props.fetchReport();
    }
  }

  renderFullReportData(data) {
    const { selectedReportView } = this.props;
    if (selectedReportView === "full") {
      return (
        <Fragment>
          <Table.Cell>{data.loginId}</Table.Cell>
          <Table.Cell>{data.employeeType}</Table.Cell>
          <Table.Cell>{data.employeeStatus}</Table.Cell>
          <Table.Cell>{data.jobTitle}</Table.Cell>
          <Table.Cell>{data.managerId}</Table.Cell>
          <Table.Cell>{data.managerName}</Table.Cell>
          <Table.Cell>{data.costCenterCode}</Table.Cell>
          <Table.Cell>{data.costCenterName}</Table.Cell>
          <Table.Cell>{data.orgUnitCode}</Table.Cell>
          <Table.Cell>{data.orgUnitName}</Table.Cell>
          <Table.Cell>{data.personnelSubAreaCode}</Table.Cell>
          <Table.Cell>{data.personnelSubAreaName}</Table.Cell>
          <Table.Cell>{data.personnelAreaCode}</Table.Cell>
          <Table.Cell>{data.personnelAreaName}</Table.Cell>
          <Table.Cell>{data.functionCode}</Table.Cell>
          <Table.Cell>{data.functionName}</Table.Cell>
        </Fragment>
      );
    }
    return <Fragment></Fragment>;
  }

  renderData() {
    const { reports } = this.props;
    const reportsData = reports; //this.prepareReportsData();
    const reportRows = reportsData.map((data, index) => {
      
      const {
        //daysOverdue,
        dayspastDuedate
      } = calculateDaysOverdueDayPastDueDate(data);

      return (
        <Table.Row
          key={index}
          className={index % 2 === 1 ? "highlight" : "normal"}
        >
          <Table.Cell>{data.staffId}</Table.Cell>
          <Table.Cell>{data.studentName}</Table.Cell>
          {this.renderFullReportData(data)}
          <Table.Cell>{data.assignVehicle}</Table.Cell>
          <Table.Cell>{data.assignVehicleCode}</Table.Cell>
          <Table.Cell>{data.assignVehicleName}</Table.Cell>
          <Table.Cell>{data.laCode}</Table.Cell>
          <Table.Cell>{data.laName}</Table.Cell>
          <Table.Cell>
            <Link
              onClick={e =>
                this.navigateToDrillDown(
                  e,
                  data.rootActivityKey,
                  data.staffId,
                  data.key
                )
              }
              to={{
                pathname: "/drillDownReport",
                search: `?key=${data.key}`
              }}
              target="_newtab"
            >
              {data.laType}
            </Link>

            {/*  <a
            href="#"
            onClick={(e) =>
              this.navigateToDrillDown(e,data.rootActivityKey, data.staffId)
            }
          >
            
            
            {data.laType}
          </a>  */}
          </Table.Cell>
          <Table.Cell>{data.assignStatus}</Table.Cell>
          <Table.Cell>{data.required_recom}</Table.Cell>
          <Table.Cell>{data.regulatoryBody}</Table.Cell>
          <Table.Cell>{data.isCertification}</Table.Cell>
          <Table.Cell>{data.assignmentDate}</Table.Cell>
          <Table.Cell>{data.dueDate}</Table.Cell>
          {/* <Table.Cell>{daysOverdue}</Table.Cell> */}
          <Table.Cell>{dayspastDuedate}</Table.Cell>
          <Table.Cell>{data.completionDate}</Table.Cell>
          <Table.Cell>{data.expirationDate}</Table.Cell>
          <Table.Cell>{data.eSignatureDate}</Table.Cell>
          <Table.Cell>{data.deCertificationDate}</Table.Cell>
        </Table.Row>
      )
    });

    return reportRows;
  }

  renderFullReportHeader() {
    const { selectedReportView } = this.props;
    const { column, direction } = this.state;
    if (selectedReportView === "full") {
      return (
        <Fragment>
          <Table.HeaderCell
            sorted={column === "loginId" ? direction : null}
            onClick={() => this.handleSort("loginId")}
            className="heading"
          >
            Login ID
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "employeeType" ? direction : null}
            onClick={() => this.handleSort("employeeType")}
            className="heading"
          >
            Employee Type
          </Table.HeaderCell>
          <Table.HeaderCell
            className="heading"
            sorted={column === "employeeStatus" ? direction : null}
            onClick={() => this.handleSort("employeeStatus")}
          >
            Employee Status
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "jobTitle" ? direction : null}
            onClick={() => this.handleSort("jobTitle")}
            className="heading"
          >
            Job Title
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "managerId" ? direction : null}
            onClick={() => this.handleSort("managerId")}
            className="heading"
          >
            Manager Staff ID
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "managerName" ? direction : null}
            onClick={() => this.handleSort("managerName")}
            className="heading"
          >
            Manager Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "costCenterCode" ? direction : null}
            onClick={() => this.handleSort("costCenterCode")}
            className="heading"
          >
            Cost Center Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "costCenterName" ? direction : null}
            onClick={() => this.handleSort("costCenterName")}
            className="heading"
          >
            Cost Center Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "orgUnitCode" ? direction : null}
            onClick={() => this.handleSort("orgUnitCode")}
            className="heading"
          >
            Org Unit Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "orgUnitName" ? direction : null}
            onClick={() => this.handleSort("orgUnitName")}
            className="heading"
          >
            Org Unit Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "personnelSubAreaCode" ? direction : null}
            onClick={() => this.handleSort("personnelSubAreaCode")}
            className="heading"
          >
            Personnel Subarea Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "personnelSubAreaName" ? direction : null}
            onClick={() => this.handleSort("personnelSubAreaName")}
            className="heading"
          >
            Personnel Subarea Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "personnelAreaCode" ? direction : null}
            onClick={() => this.handleSort("personnelAreaCode")}
            className="heading"
          >
            Personnel Area Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "personnelAreaName" ? direction : null}
            onClick={() => this.handleSort("personnelAreaName")}
            className="heading"
          >
            Personnel Area Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "functionCode" ? direction : null}
            onClick={() => this.handleSort("functionCode")}
            className="heading"
          >
            Function Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "functionName" ? direction : null}
            onClick={() => this.handleSort("functionName")}
            className="heading"
          >
            Function Name
          </Table.HeaderCell>
        </Fragment>
      );
    }
    return <Fragment></Fragment>;
  }

  renderHeader() {
    const { column, direction } = this.state;
    return (
      <Table.Row>
        <Table.HeaderCell
          className="heading"
          sorted={column === "staffId" ? direction : null}
          onClick={() => this.handleSort("staffId")}
        >
          Staff ID
        </Table.HeaderCell>
        <Table.HeaderCell
          className="heading"
          sorted={column === "studentName" ? direction : null}
          onClick={() => this.handleSort("studentName")}
        >
          Student Name
        </Table.HeaderCell>
        {this.renderFullReportHeader()}
        <Table.HeaderCell
          className="heading"
          sorted={column === "assignVehicle" ? direction : null}
          onClick={() => this.handleSort("assignVehicle")}
        >
          Assignment Vehicle
        </Table.HeaderCell>
        <Table.HeaderCell
          className="heading"
          sorted={column === "assignVehicleCode" ? direction : null}
          onClick={() => this.handleSort("assignVehicleCode")}
        >
          Assignment Vehicle Code
        </Table.HeaderCell>
        <Table.HeaderCell
          className="heading"
          sorted={column === "assignVehicleName" ? direction : null}
          onClick={() => this.handleSort("assignVehicleName")}
        >
          Assignment Vehicle Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === "laCode" ? direction : null}
          onClick={() => this.handleSort("laCode")}
          className="heading"
        >
          LA Code
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === "laName" ? direction : null}
          onClick={() => this.handleSort("laName")}
          className="heading"
        >
          LA Name
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === "laType" ? direction : null}
          onClick={() => this.handleSort("laType")}
          className="heading"
        >
          LA Type
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === "assignStatus" ? direction : null}
          onClick={() => this.handleSort("assignStatus")}
          className="heading"
        >
          Assignment Status
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === "required_recom" ? direction : null}
          onClick={() => this.handleSort("required_recom")}
          className="heading"
        >
          Required/ Recommended
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === "regulatoryBody" ? direction : null}
          onClick={() => this.handleSort("regulatoryBody")}
          className="heading"
        >
          Regulatory Body
        </Table.HeaderCell>
        <Table.HeaderCell className="heading">
          Is Certification
        </Table.HeaderCell>
        <Table.HeaderCell
          /* sorted={column === "assignmentDate" ? direction : null}
          onClick={() => this.handleSort("assignmentDate")} */
          className="heading"
        >
          Assignment Date
        </Table.HeaderCell>
        <Table.HeaderCell
          /* sorted={column === "dueDate" ? direction : null}
          onClick={() => this.handleSort("dueDate")} */
          className="heading"
        >
          Due Date
        </Table.HeaderCell>
        <Table.HeaderCell
          /*  sorted={column === "dayspastDuedate" ? direction : null}
          onClick={() => this.handleSort("dayspastDuedate")} */
          className="heading"
        >
          Days Past Due
        </Table.HeaderCell>
        {/* <Table.HeaderCell
          
          className="heading"
        >
          Days Overdue
        </Table.HeaderCell> */}
        <Table.HeaderCell
          /*  sorted={column === "completionDate" ? direction : null}
          onClick={() => this.handleSort("completionDate")} */
          className="heading"
        >
          Last Completion Date
        </Table.HeaderCell>
        
        <Table.HeaderCell
          /* sorted={column === "expirationDate" ? direction : null}
          onClick={() => this.handleSort("expirationDate")} */
          className="heading"
        >
          Expiration Date
        </Table.HeaderCell>
        <Table.HeaderCell
          /* sorted={column === "eSignatureDate" ? direction : null}
          onClick={() => this.handleSort("eSignatureDate")} */
          className="heading"
        >
          E-Signature Date
        </Table.HeaderCell>
        <Table.HeaderCell
          /* sorted={column === "deCertificationDate" ? direction : null}
          onClick={() => this.handleSort("deCertificationDate")} */
          className="heading"
        >
          De-Certification Date
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  render() {
    //const {selectedReportView}=this.props.selectedReportView;
    //style={{wordBreak:selectedReportView!='full'?"break-all":"normal"}}
    const { hasError, reports, isThresholdHit } = this.props;
    if (hasError) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching reports"
          errorDescription={hasError}
        />
      );
    }
    if (isThresholdHit) {
      return (
        <Segment className="tableAlign">
          <div className="middleDiv">
            <Message display="Due to the large number of records, your data cannot be displayed. Please use the download buttons above to export your data to xlsx or pdf" />
          </div>
        </Segment>
      );
    }
    if (reports != null) {
      if (reports.length > 0) {
        return (
          <div className="scroll-report">
            {/* <ExecutionTime time={this.props.reportQueryExecutionTime} /> */}
            <Table striped className="table-margin" sortable>
              <Table.Header>{this.renderHeader()}</Table.Header>
              <Table.Body>{this.renderData()}</Table.Body>
            </Table>
          </div>
        );
      }
      return (
        <Segment className="tableAlign">
          {/* <ExecutionTime time={this.props.reportQueryExecutionTime} /> */}
          <div className="middleDiv">
            <Message display="No records found" />
          </div>
        </Segment>
      );
    }
    return (
      <Segment className="tableAlign">
        <Progressbar Message="Reports" />
      </Segment>
    );
  }
}
const mapStateToProps = state => {
  return {
    reportQueryExecutionTime: state.reportQueryExecutionTime,
    reports: state.reportInfo,
    credential: state.credential,
    isThresholdHit: state.isThresholdHit,
    hasError:
      state.errors &&
      state.errors.some(code => code.errorCode === FETCH_REPORT_FAIL) &&
      state.errors.filter(val => val.errorCode === FETCH_REPORT_FAIL)[0].data,
    selectedReportView: state.selectedReportView
  };
};

export default connect(
  mapStateToProps,
  { fetchReport, sortReport }
)(DetailedReport);
