export default {
  oidc: {
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID, //Prod Okta tenant app clientID
    issuer: "https://amgen.okta.com/oauth2/default", //Prod Okta tenant app issuer
    redirectUri: window.location.origin + "/implicit/callback",
    scope: "openid"
  },
  webAppConfiguration: {
    sasalbUrl:
      process.env.NODE_ENV.trim() === "development"
        ? "https://www.amgenlmsjsreports-dev.com/api/sasreport"  // localhost mode
        : window.location.origin + "/api/sasreport", // awsdev, test, production modes
    tasalbUrl:
    process.env.NODE_ENV.trim() === "development" 
        ? "https://www.amgenlmsjsreports-dev.com/api/tasreport" // localhost mode
        : window.location.origin + "/api/tasreport" // awsdev, test, production modes
  }
};
