import React, { Component } from "react";
import { Container, Button, Grid } from "semantic-ui-react";
import { Route } from "react-router-dom";

import StaffFunctions from "./StaffFunctions";
import SearchManager from "./StaffManagerSearch";
import StaffManagers from "./StaffManagers";
import TASAVReport from "./TASAVReport";
import Home from "../Home/Home";
import "./TASReport.css";
import { connect } from "react-redux";

let toggledContent = null;
class TASReport extends Component {
  constructor() {
    super();

    this.state = {
      screenName: "TASPrompt"
    };
  }

  renderManagerSearch() {
    if (this.props.selectedStaffFunction) {
      return (
        <Grid.Row className="gridMargin">
          <b>Select Staff's Manager : </b>
          <SearchManager />
        </Grid.Row>
      );
    }
  }
  renderManagersDisplay() {
    if (this.props.selectedStaffFunction) {
      return (
        <Grid.Row className="gridMargin">
          <StaffManagers />
        </Grid.Row>
      );
    }
  }
  renderLinkBreak() {
    if (this.props.selectedStaffFunction) {
      return <hr className="hrwidth"></hr>;
    }
  }

  render() {    
    let toggledContent = null;
    const isNextButtonDisabled =
      !this.props.selectedManagers ||
      Object.values(this.props.selectedManagers).length === 0;

    if (this.state.screenName === "Home") {
      toggledContent = <Route path="/" component={Home} />;
    } else if (this.state.screenName === "TASPrompt") {
      toggledContent = (
        <Container className="top">
          <Grid className="gridMargin ">
            <Grid.Row className="gridMargin">
              <b>Select Staff Function :</b>
              <StaffFunctions />
            </Grid.Row>
            <hr className="hrwidth"></hr>
            {this.renderManagerSearch()}
            {this.renderManagersDisplay()}
            {this.renderLinkBreak()}
          </Grid>

          <Button
            basic
            className="submit-cancle"
            color="blue"
            onClick={() => this.setState({ screenName: "TAS_AV" })}
            disabled={isNextButtonDisabled}
          >
            {/* <Link className="submit">Next</Link> */}
            Next
          </Button>
          <Button
            basic
            className="submit-cancle"
            onClick={() => this.setState({ screenName: "Home" })}
          >
            Cancel
          </Button>
        </Container>
      );
    } else if (this.state.screenName === "TAS_AV") {
      toggledContent = <TASAVReport />;
    }
    return <div>{toggledContent}</div>;
  }
}

const mapStateToProps = state => {
  return {
    selectedManagers: state.selectedManagers,
    selectedStaffFunction: state.selectedStaffFunction,
    managers: state.staffManagers
  };
};

export default connect(mapStateToProps)(TASReport);
