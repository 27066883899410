import React, { Component } from "react";
import ReportHeader from "./ReportHeader";
import DetailedReport from "./DetailedReport";
import SummaryReport from "./SummaryReport";
import "./Report.css";
import { Segment, Tab, Button, Icon } from "semantic-ui-react";
import SASReport from "../SASReport";
import { connect } from "react-redux";
import {  clearSummary, clearReport } from "../actions";

class Report extends Component {
  constructor(props) {
    super();
    this.filteredApplied = this.filteredApplied.bind(this);
  }
  state = {
    activeIndex: 0,
    loadSASReportComponent: false,
    isReportLoading: true,
    disableReportTab: true
  };

  filteredApplied() {
    this.setState({ activeIndex: 0 });
    this.props.clearSummary();
    this.props.clearReport();
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
  };

  handleReportLoad = isLoading => this.setState({ isReportLoading: isLoading });

  renderReportHeader() {
    return (
      <div style={{ float: "left", marginTop: "-6px" }}>
        {this.props.isSelfMode ? null : (
          <Button
            basic
            onClick={() => this.setState({ loadSASReportComponent: true })}
            className="button-hover"
          >
            <Icon name="backward" className="icon-color" />
            Go Back
          </Button>
        )}
        <ReportHeader onFilterApply={this.filteredApplied} />
      </div>
    );
  }
  loadPanes() {
    const panes = [
      {
        menuItem: "Report Summary",
        key: "Report Summary",
        render: () => (
          <Tab.Pane key="pane 1">
            <SummaryReport  />
          </Tab.Pane>
        )
      },
      {
        menuItem: {
          disabled: !this.props.isSummaryLoaded,
          content: "Detail Report"
        },
        key: "Detail Report",
        render: () => (
          <Tab.Pane key="pane 2">
            <DetailedReport />
          </Tab.Pane>
        )
      }
    ];
    return panes;
  }

  renderReport() {
    return (
      <Segment key="tab1" className="tableAlign">
        {this.renderReportHeader()}

        <Tab
          key="tabular"
          renderActiveOnly={true}
          className="tab-style"
          activeIndex={this.state.activeIndex}
          panes={this.loadPanes()}
          onTabChange={this.handleTabChange}
        />
      </Segment>
    );
  }

  render() {
    if (this.state.loadSASReportComponent) {
      return <SASReport />;
    }
    return this.renderReport();
  }
}
const mapStateToProps = state => {
  return {
    isSummaryLoaded: state.summaryInfo && state.summaryInfo.length > 0,
    isSelfMode: state.selectedUserMode === "self",
    applyFilter: state.applyFilter
  };
};

export default connect(
  mapStateToProps,
  { clearSummary, clearReport }
)(Report);
