import React, { Component } from "react";
import { Button, Modal, Image, List, Icon, Checkbox } from "semantic-ui-react";
import Lov from "../Lov/Lov";
import "./Report.css";
import filterImage from "./images/filter.svg";
import iconFinderImage from "./images/iconfinder_Close_1031533.svg";
import listImage from "./images/list.svg";
import {
  generateReportPDF,
  generateReportExcel,
  addStaff,
  removeStaff,
  removeAllStaff,
  setSummaryForFilterGenerated
} from "../actions";
import { connect } from "react-redux";
import withLoader from "../../Common/controls/Loader/Loader";
import {
  downloadSignedURLFile,
  handleExcelPdfErrorresponses
} from "../../../helpers";
import {
  GENERATE_EXCEL_FAIL,
  GENERATE_PDF_FAIL
} from "../../Common/errorcodes/errorcodes";

// var userAgent = window.navigator.userAgent.toLowerCase(),
//   ios = /iphone|ipod|ipad/.test(userAgent);

class ReportHeader extends Component {
  state = { modalOpen: false };
  close = () => this.setState({ open: false });
  showFilter = dimmer => () => this.setState({ dimmer, open: true });
  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });
  onStaffChange = (e, staffId) => {
    if (!e.target.previousSibling.checked) {
      this.props.addStaff(staffId);
    } else {
      this.props.removeStaff(staffId);
    }
  };
  onApply = () => {
    this.close();
    this.handleClose();
    this.props.onFilterApply();
  };

  componentDidMount() {
    this.addAllStaff();
  }

  componentDidUpdate() {
    const { summariesForFilter, isSummaryForFilterGenerated } = this.props;
    if (summariesForFilter) {
      if (summariesForFilter.length === 0 && summariesForFilter.length > 0) {
        this.props.removeAllStaff();
      }
    }
    if (!isSummaryForFilterGenerated) {
      this.props.setSummaryForFilterGenerated(true);
    }
  }

  addAllStaff() {
    const { summariesForFilter, selectedStaff } = this.props;
    if (summariesForFilter) {
      for (let i = 0; i < summariesForFilter.length - 1; i++) {
        if (!selectedStaff.includes(summariesForFilter[i].staffId)) {
          this.props.addStaff(summariesForFilter[i].staffId);
        }
      }
    }
  }

  onCheckAllChange(e) {
    if (!e.target.previousSibling.checked) {
      this.addAllStaff();
    } else {
      this.props.removeAllStaff();
    }
  }

  renderStaff() {
    const { selectedStaff, summariesForFilter } = this.props;
    var summariesExceptLastRecord = summariesForFilter.slice(
      -summariesForFilter.length,
      summariesForFilter.length - 1
    );

    return (
      <React.Fragment>
        <Checkbox
          label="Select All"
          onChange={e => this.onCheckAllChange(e)}
          checked={selectedStaff.length === summariesForFilter.length - 1}
        />
        <List horizontal className="scroll">
          {summariesExceptLastRecord.map((staff, i) => (
            <List.Item className="opsList" key={i}>
              <Checkbox
                label={staff.studentName}
                name={staff.studentName}
                value={staff.staffId}
                onChange={e => this.onStaffChange(e, staff.staffId)}
                checked={selectedStaff && selectedStaff.includes(staff.staffId)}
              />
            </List.Item>
          ))}
        </List>
      </React.Fragment>
    );
  }

  handleExportPdfClick = async () => {
    try {
      this.props.showLoader();
      var returnValue = await this.props.generateReportPDF();
      if (returnValue === null)
        downloadSignedURLFile(this.props.fetchPDF.documentURL);
      else handleExcelPdfErrorresponses(returnValue);
    } finally {
      this.props.hideLoader();
    }

    /*
    try {
      this.props.showLoader();
      await this.props.generateReportPDF();
      downloadSignedURLFile(this.props.fetchPDF.documentURL);
    } catch (error) {
      // alert to be shown and return.
      window.alert(
        "Error in generating PDF : " + error + ". Please try in some time. "
      );
      return;
    } finally {
      this.props.hideLoader();
    }
    */
  };

  handleExportExcelClick = async () => {
    try {
      this.props.showLoader();

      /*  await this.props.generateReportExcel();
      downloadSignedURLFile(this.props.fetchExcel.documentURL);
    } catch (err) {
      debugger;
      // alert to be shown and return.
      window.alert(
        "Error in generating Excel : " + err.response.data + ". Please try in some time. "
      );
      return; */
      var returnValue = await this.props.generateReportExcel();
      if (returnValue === null)
        downloadSignedURLFile(this.props.fetchExcel.documentURL);
      else handleExcelPdfErrorresponses(returnValue);
    } finally {
      this.props.hideLoader();
    }
  };

  renderReportHeader() {
    const { open } = this.state;
    const { summariesForFilter, isSelfMode } = this.props;
    return (
      <div className="search-div">
        <div className="float-left" style={{ marginTop: "-6px" }}>
          <Button
            disabled={!summariesForFilter}
            basic
            className="button-hover"
            onClick={() => this.handleExportPdfClick()}
          >
            <Icon name="file pdf outline" className="icon-color" />
            PDF
          </Button>

          <Button
            disabled={!summariesForFilter}
            basic
            className="button-hover"
            onClick={() => this.handleExportExcelClick()}
          >
            <Icon name="file excel outline" className="icon-color" />
            Excel
          </Button>
        </div>
        {/* {!ios ? (
          <div className="float-left" style={{ marginTop: "-6px" }}>
            <Button
              disabled={!summaries}
              basic
              className="button-hover"
              onClick={() => this.handleExportPdfClick()}
            >
              <Icon name="file pdf outline" className="icon-color" />
              PDF
            </Button>

            <Button
              disabled={!summaries}
              basic
              className="button-hover"
              onClick={() => this.handleExportExcelClick()}
            >
              <Icon name="file excel outline" className="icon-color" />
              Excel
            </Button>
          </div>
        ) : null} */}
        {!isSelfMode ? (
          <Button
            disabled={!summariesForFilter}
            onClick={this.showFilter(true)}
            className="filter-styles align"
          >
            <Image src={filterImage} onClick={this.close} />
          </Button>
        ) : null}
        <Modal
          open={open}
          onClose={this.close}
          className="modalSAS"
          centered={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            Filter
            <Image
              src={iconFinderImage}
              onClick={this.close}
              className="close"
            />
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Lov />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button className="button-hover" basic onClick={this.close}>
              Cancel
            </Button>
            <Button className="button-hover" basic onClick={this.onApply}>
              Apply
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          style={{ margin: "1rem auto" }}
          className="modalSAS"
          centered={false}
          closeOnDimmerClick={false}
          trigger={
            !isSelfMode ? (
              <Button
                disabled={!summariesForFilter}
                onClick={this.handleOpen}
                className="filter-styles align"
              >
                <Image src={listImage} />
              </Button>
            ) : null
          }
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          <Modal.Header>
            Students List
            <Image
              src={iconFinderImage}
              onClick={this.handleClose}
              className="close"
            />
          </Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              {summariesForFilter != null ? this.renderStaff() : ""}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button className="button-hover" basic onClick={this.handleClose}>
              Cancel
            </Button>
            <Button className="button-hover" basic onClick={this.onApply}>
              Apply
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }

  render() {
    return this.renderReportHeader();
  }
}

const mapStateToProps = state => {
  return {
    fetchExcel: state.fetchExcel,
    fetchPDF: state.fetchPDF,
    // errors: state.errors,
    pdfError:
      state.errors &&
      state.errors.some(code => code.errorCode === GENERATE_PDF_FAIL) &&
      state.errors.filter(val => val.errorCode === GENERATE_PDF_FAIL)[0].data,
    excelError:
      state.errors &&
      state.errors.some(code => code.errorCode === GENERATE_EXCEL_FAIL) &&
      state.errors.filter(val => val.errorCode === GENERATE_EXCEL_FAIL)[0].data,
    selectedStaff: state.selectedStaff,
    isSelfMode: state.selectedUserMode === "self",
    isSummaryForFilterGenerated: state.isSummaryForFilterGenerated,
    summariesForFilter: state.summariesForFilter
  };
};

export default withLoader(
  connect(
    mapStateToProps,
    {
      generateReportPDF,
      generateReportExcel,
      addStaff,
      removeStaff,
      removeAllStaff,
      setSummaryForFilterGenerated
    }
  )(ReportHeader)
);
