import React from "react";
import Message from "./Message";
import { Segment } from "semantic-ui-react";

const ErrorMessage = props => {
  return (
    <Segment className="tableAlign">
      <Message display={props.errorText} />
      <Message
        display={
          "Error Description: " +
          (props.errorDescription.response
            ? props.errorDescription.response.status +
              (props.errorDescription.response.statusText !== ""
                ? " - " + props.errorDescription.response.statusText
                : " - " + props.errorDescription.response.data.data)
            : props.errorDescription.message)
        }
      />
    </Segment>
  );
};

export default ErrorMessage;
