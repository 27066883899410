import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withLoader from "../Common/controls/Loader/Loader";
import _isEqual from "lodash/isEqual";
import {
  downloadSignedURLFile,
  handleExcelPdfErrorresponses
} from "../../helpers";
import { Icon, Container, Card, Button, Image } from "semantic-ui-react";
import "./TASAVReport.css";
import {
  fetchLAReportData,
  generatelareportpdf,
  generatelareportexcel
} from "./actions";
import Progressbar from "../Common/controls/Progressbar/Progressbar";
import { TAS_REPORT_FETCH_LA_DATA_FAIL } from "../Common/errorcodes/errorcodes";
import Message from "../Common/controls/Message/Message";
import ErrorMessage from "../Common/controls/Message/ErrorMessage";
import queryString from "query-string";
import GreenDot from "./images/green.png";
import RedDot from "./images/red.png";
import GreyDot from "./images/grey.png";
import GoldenDot from "./images/golden.png";
import withFixedColumnsScrollEvent from "react-table-hoc-fixed-columns";
// import ExecutionTime from "../Common/controls/ExecutionTime/ExecutionTime";
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

const ReactTableFixedColumns = withFixedColumnsScrollEvent(ReactTable);
var isIE = false || !!document.documentMode;
var isLargeMonitor =
  window.screen.width === 1920 && window.screen.height === 1080 ? true : false;

class TASLAReport extends Component {
  state = { laDataObj: undefined };

  componentDidMount() {
    const { credential, laReportData, location } = this.props;
    if (credential && !laReportData) {
      const url = queryString.parse(location.search);

      if ("key" in url) {
        const data = JSON.parse(window.localStorage.getItem(url.key));
        if (data) {
          this.props.fetchLAReportData(
            data.staffFunction,
            data.managers,
            data.selectedAVKey,
            data.staffs,
            data.costCenters,
            data.locations
          );
        } else {
          this.props.fetchLAReportData("", "", "", "", "", "");
        }
      }
    }
  }

  componentDidUpdate() {
    const { laReportData, location } = this.props;
    if (!laReportData) {
      const url = queryString.parse(location.search);

      if ("key" in url) {
        const data = JSON.parse(window.localStorage.getItem(url.key));
        if (data) {
          this.props.fetchLAReportData(
            data.staffFunction,
            data.managers,
            data.selectedAVKey,
            data.staffs,
            data.costCenters,
            data.locations
          );
        } else {
          this.props.fetchLAReportData("", "", "", "", "", "");
        }
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.laReportData, this.props.laReportData)) {
      this.setState({
        laDataObj: JSON.parse(nextProps.laReportData)
      });
    }
  }

  handleExportPdfClick = async (event, data, av, lmsDataRefresh) => {
    try {
      this.props.showLoader();
      var returnValue = await this.props.generatelareportpdf(
        data,
        av,
        lmsDataRefresh
      );
      if (returnValue === null)
        downloadSignedURLFile(this.props.laReportPdf.documentURL);
      else handleExcelPdfErrorresponses(returnValue);
    } finally {
      this.props.hideLoader();
    }
  };

  handleExportExcelClick = async (event, data, av, lmsDataRefresh) => {
    try {
      this.props.showLoader();
      var returnValue = await this.props.generatelareportexcel(
        data,
        av,
        lmsDataRefresh
      );
      if (returnValue === null)
        downloadSignedURLFile(this.props.laReportExcel.documentURL);
      else handleExcelPdfErrorresponses(returnValue);
    } finally {
      this.props.hideLoader();
    }
  };

  renderHeaderData() {
    const { laDataObj } = this.state;

    var headerData = [];
    headerData.push({
      Header: "Manager Name",
      fixed: "left",
      columns: [
        {
          Header: "Learning Activities",
          accessor: "la",
          width: 300
        },
        {
          Header: "Total Qualified",
          accessor: "tq",
          width: 100
        }
      ]
    });
    const laData =
      laDataObj.dataGroupedByLearningActivity[
        Object.keys(laDataObj.dataGroupedByLearningActivity)[0]
      ];

    laData.MLAData.map((manager, j) => {
      manager.SLAData.map(staff => {
        headerData.push({
          Header: manager.MN + " (" + manager.MId + ")",
          id: manager.MId,
          columns: [
            {
              Header: staff.SN + " (" + staff.SId + ")",
              accessor: String(staff.SId),
              id: staff.SId,
              width: 120,
              sortable: false
            }
          ]
        });
      });
    });
    return headerData;
  }

  renderScrollableData() {
    const { laDataObj } = this.state;
    var reportBodyData = [];

    Object.keys(laDataObj.dataGroupedByLearningActivity)
      .sort()
      .map((learningactivity, i) => {
        const laData =
          laDataObj.dataGroupedByLearningActivity[learningactivity];
        var outputData = {};

        outputData["la"] = laData.LA;
        outputData["tq"] = laData.TQ;

        laData.MLAData.map((manager, j) => {
          manager.SLAData.map((staff, k) => {
            outputData[staff.SId] = (
              <Image
                src={
                  staff.STCOLOR === "G"
                    ? GreenDot
                    : staff.STCOLOR === "R"
                    ? RedDot
                    : staff.STCOLOR === "Y"
                    ? GoldenDot
                    : GreyDot
                }
                className="statusAlign"
              />
            );
          });
        });
        reportBodyData.push(outputData);
      });
    return reportBodyData;
  }

  render() {
    const { laDataObj } = this.state;
    const { errors, location } = this.props;

    const url = queryString.parse(location.search);
    var av;

    if ("key" in url) {
      const data = JSON.parse(window.localStorage.getItem(url.key));
      if (data) {
        av = data.selectedAV;
      }
    }

    if (
      errors &&
      errors.find(code => code.errorCode === TAS_REPORT_FETCH_LA_DATA_FAIL) &&
      errors.find(code => code.errorCode === TAS_REPORT_FETCH_LA_DATA_FAIL)[0]
        .data
    ) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching LA report data"
          errorDescription={
            errors.filter(
              code => code.errorCode === TAS_REPORT_FETCH_LA_DATA_FAIL
            )[0].data
          }
        />
      );
    }

    if (laDataObj) {
      if (Object.values(laDataObj.dataGroupedByLearningActivity).length) {
        return (
          <Container className="top">
            <h4 class="avHeading">{av}</h4>

            <div class="instruction instructionLA">
              <span>
                For latest course assignment/completion status you must go to
                LMS.
              </span>
              <Card.Group className="alignment">
                <div className="legend">
                  <Image src={GreenDot} className="statusImage" />
                  <span>(Y) Completed</span>
                </div>
                <div className="legend">
                  <Image src={RedDot} className="statusImage" />
                  <span>(N) Incomplete</span>
                </div>
                <div className="legend">
                  <Image src={GoldenDot} className="statusImage" />
                  <span>(E) Expires in 3 days or less</span>
                </div>
                <Button
                  basic
                  className="export-button"
                  onClick={e =>
                    this.handleExportExcelClick(
                      e,
                      laDataObj.dataGroupedByLearningActivity,
                      av,
                      laDataObj.lmsDataRefresh
                    )
                  }
                >
                  <Icon name="file excel outline" className="icon-color" />
                  Export Excel
                </Button>
                <Button
                  basic
                  className="export-button"
                  onClick={e =>
                    this.handleExportPdfClick(
                      e,
                      laDataObj.dataGroupedByLearningActivity,
                      av,
                      laDataObj.lmsDataRefresh
                    )
                  }
                >
                  <Icon name="file pdf outline" className="icon-color" />
                  Export PDF
                </Button>
              </Card.Group>
              <div>
                Information on this report reflects LMS data as of&nbsp;
                {laDataObj.lmsDataRefresh}
              </div>
            </div>
            <ReactTableFixedColumns
              data={this.renderScrollableData()}
              columns={this.renderHeaderData()}
              defaultPageSize={isLargeMonitor ? 10 : isIE ? 5 : 10}
              style={{ height: 480 }}
              // style={{ height: 463 }}
              className="-striped fixedTable"
            />
            {/* <ExecutionTime time={this.props.laQueryExecutionTime} /> */}
          </Container>
        );
      }
      return (
        <Container className="top">
          <Message display="No records found" />
        </Container>
      );
    }
    return (
      <Container className="top">
        <React.Fragment>
          <Progressbar Message="LA Report" />
        </React.Fragment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    credential: state.credential,
    selectedStaffFunction: state.selectedStaffFunction,
    laReportData: state.laReportData,
    selectedManagers: state.selectedManagers,
    laReportPdf: state.lapdf,
    laReportExcel: state.laexcel,
    selectedAssignmentVehicle: state.selectedAssignmentVehicle,
    errors: state.errors,
    laQueryExecutionTime: state.laQueryExecutionTime
  };
};

export default withLoader(
  withRouter(
    connect(
      mapStateToProps,
      {
        fetchLAReportData,
        generatelareportpdf,
        generatelareportexcel
      }
    )(TASLAReport)
  )
);
