import React, { Component } from "react";
import { ManagerRemove } from "./actions";
import { connect } from "react-redux";
import { List, Checkbox } from "semantic-ui-react";

class StaffManagers extends Component {
  onManagerChange = (e, result) => {
    const manager = { name: result.value };
    this.props.ManagerRemove(manager);
  };

  render() {
    const { selectedManagers } = this.props;

    if (selectedManagers) {
      return (
        <List horizontal className="listHeight">
          {selectedManagers.map((option, i) => {
            return (
              <List.Item className="opsList" key={option.name}>
                <Checkbox
                  label={option.name}
                  value={option.name}
                  checked={true}
                  onChange={this.onManagerChange}
                />
              </List.Item>
            );
          })}
        </List>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    credential: state.credential,
    selectedManagers: state.selectedManagers
  };
};

export default connect(
  mapStateToProps,
  { ManagerRemove }
)(StaffManagers);
