import React, { Component, Fragment } from "react";
import { List, Segment } from "semantic-ui-react";
import { default as EmpStatus } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import { connect } from "react-redux";
import { addEmployeeStatus, removeEmployeeStatus } from "../actions";
class EmployeeStatus extends Component {
  onEmployeeStatusChange = (e, status, id) => {
    // status = { status, id };
    if (!e.target.previousSibling.checked) {
      this.props.addEmployeeStatus(status);
    } else {
      this.props.removeEmployeeStatus(status);
    }
  };

  renderEmployeeStatus() {
    return this.props.empStatuses
      .filter(emp => emp.empStatus.toLowerCase() !== "Retired".toLowerCase())
      .map(e => (
        <EmpStatus
          key={e.empStatusId}
          checked={this.props.selectedEmpStatuses.includes(e.empStatus)}
          classname="list_style"
          label={e.empStatus}
          name={`${e.empStatus}_${e.empStatusId}`}
          value={e.empStatusId}
          handlechange={event =>
            this.onEmployeeStatusChange(event, e.empStatus, e.empStatusId)
          }
        />
      ));
  }

  render() {
    if (this.props.empStatuses) {
      return (
        <Segment raised>
          <h5>
            <b>Select Employee Status</b>
          </h5>
          <div className="scroll" style={{ maxHeight: "20vh" }}>
            <List>{this.renderEmployeeStatus()}</List>
          </div>
        </Segment>
      );
    }
    return <Fragment></Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    selectedEmpStatuses: state.selectedEmpStatuses
  };
};
export default connect(
  mapStateToProps,
  { addEmployeeStatus, removeEmployeeStatus }
)(EmployeeStatus);
