import React, { Component, Fragment } from "react";
import { Button, Modal } from "semantic-ui-react";
import decode from "jwt-decode";
import { withRouter } from "react-router-dom";

class Timer extends Component {
  state = { modalOpen: false, tokenExpiration: null, expiredModalOpen: false };
  redirectToLogin = async () => {
    const sessionExist = await this.props.auth._oktaAuth.session.exists();
    if (sessionExist) {
      await this.props.auth._oktaAuth.session.close();
    }
    this.props.auth._oktaAuth.tokenManager.clear();
    window.location = window.location.origin;
  };
  handleClose = () => this.setState({ modalOpen: false });
  closeExpiredModal = () => {
    this.setState({ expiredModalOpen: false });
  };
  async renewTokens() {
    const sessionExist = await this.props.auth._oktaAuth.session.exists();
    if (sessionExist) {
      console.log("first session exists");
    } else {
      console.log("first session not exist");
    }

    const session1 = await this.props.auth._oktaAuth.session.get();
    const secsessionExist = await this.props.auth._oktaAuth.session.exists();

    if (secsessionExist) {
      console.log("second session exists");
    } else {
      console.log("second session not exist");
    }

    let tokens = await this.props.auth._oktaAuth.token.getWithoutPrompt({
      responseType: ["id_token", "token"],
      sessionToken: session1.id
    });

    tokens = Array.isArray(tokens) ? tokens : [tokens];
    for (let token of tokens) {
      if (token.idToken) {
        this.props.auth._oktaAuth.tokenManager.add("idToken", token);
      } else if (token.accessToken) {
        this.props.auth._oktaAuth.tokenManager.add("accessToken", token);
      }
    }
    this.setState({ modalOpen: false });
  }
  renewSession = async () => {
    this.renewTokens();
  };
  /*  async componentDidUpdate() {
    try {
      var idToken = await this.props.auth.getIdToken();
      if (idToken) {
        var decoded = decode(idToken);
        const currentTokenExpiration = new Date(decoded.exp * 1000);
        if (
          this.state.tokenExpiration === null ||
          currentTokenExpiration.getTime() !==
            this.state.tokenExpiration.getTime()
        ) {
          let initialExpiry = new Date(currentTokenExpiration);
          initialExpiry.setMinutes(initialExpiry.getMinutes() - 5);
          let currentTime = new Date().getTime();
          if (
            currentTime > initialExpiry.getTime() &&
            currentTime < currentTokenExpiration.getTime()
          ) {
            this.setState({ modalOpen: true });
          } else {
            this.executeAt(initialExpiry.getTime(), () => {
              this.setState({ modalOpen: true });
            });
          }

          let endExpiry = new Date(currentTokenExpiration);
          //endExpiry.setMinutes(endExpiry.getMinutes() - 58);
          currentTime = new Date().getTime();
          console.log("expiring in :" + new Date(endExpiry.getTime()));
          this.executeAt(endExpiry.getTime(), () => {
            this.setState({ modalOpen: false });
            this.setState({ expiredModalOpen: true });
          });

          this.setState({ tokenExpiration: currentTokenExpiration });
        }
      }
    } catch (err) {
      console.log(err);
    }
  } */
  async componentDidUpdate() {
    try {
      var idToken = await this.props.auth.getIdToken();
      if (idToken) {
        var decoded = decode(idToken);
        const currentTokenExpiration = new Date(decoded.exp * 1000);
        this.executeAt(currentTokenExpiration.getTime(), () => {
          this.setState({ expiredModalOpen: true });
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  executeAt(time, func) {
    const currentTime = new Date().getTime();
    setTimeout(func, time - currentTime);
    return true;
  }

  render() {
    return (
      <Fragment>
        <Modal
          closeIcon
          size="small"
          style={{ height: "20vh", marginTop: "30vh" }}
          centered={false}
          open={this.state.modalOpen}
        >
          <Modal.Header>
            <p style={{ fontSize: "16px" }}>Session Expiring</p>
          </Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <p style={{ fontSize: "14px" }}>
                Your current login session will expire in 10 minutes. Do you
                like to renew your login session?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={this.renewSession}
            />
            <Button negative onClick={this.handleClose}>
              No
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          centered={false}
          open={this.state.expiredModalOpen}
          size="small"
          style={{ height: "22vh", marginTop: "30vh" }}
        >
          <Modal.Header>Session Expired</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <p style={{ fontSize: "14px" }}>
                Your previous login session expired and you have been logged out
                of application.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="button-hover submit-cancle"
              color="blue"
              basic
              onClick={this.redirectToLogin}
            >
              Login
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(Timer);
