import React from "react";
import { List, Checkbox } from "semantic-ui-react";
import classes from "./ListItemCB.module.css";
const ListItemCB = props => {
  const { classname, handlechange, ...cprops } = props;
  return (
    <List.Item className={classes[classname]}>
      <Checkbox
        {...cprops}
        onChange={handlechange}
      />
    </List.Item>
  );
};

export default ListItemCB;
