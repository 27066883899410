import {
  selectedReportType,
  selectedActivityType,
  fetchEmployees,
  learnerAdd,
  learnerRemove,
  activityAdd,
  activityRemove,
  fetchActivitiesType,
  fetchLovType,
  addEmpStatus,
  removeEmpStatus,
  addEmpType,
  removeEmpType,
  addAssignType,
  removeAssignType,
  addRegBody,
  removeRegBody,
  addCert,
  removeCert,
  addAssignStatus,
  removeAssignStatus,
  fetchAllAssignments,
  assignmentAdd,
  assignmentRemove,
  removeAllAssignments,
  removeAllActivities,
  summary,
  report,
  drilldownType,
  generatePDF,
  generateExcel,
  clearReportData,
  reportViewType,
  addStaffType,
  removeStaffType,
  removeAllStaffType,
  resetSasType,
  removeAllLearnersType,
  clearSummaryData,
  userMode,
  isSummaryReGeneratedType,
  summaryForFilter,
  removeSummaryForFilter,
  thresholdCrossed,
  fetchEmpKey,
  fetchActivityKeyType,
  summaryExecutionTime,
  reportExecutionTime,
  drilldownExecutionTime
} from "./types";
import { generateUUID } from "../../../helpers";
import _ from "lodash";
import { addError, removeError } from "../../Common/actions/types";
import {
  FETCH_REPORT_FAIL,
  FETCH_LEARNERS_FAIL,
  FETCH_ASSIGNMENTS_FAIL,
  FETCH_ACTIVITIES_FAIL,
  FETCH_LOV_FAIL,
  FETCH_DRILLDOWN_REPORT_FAIL,
  FETCH_SUMMARY_FAIL,
  GENERATE_EXCEL_FAIL,
  GENERATE_PDF_FAIL,
  FETCH_EMPKEY_FAIL,
  FETCH_ACTKEY_FAIL
} from "../../Common/errorcodes/errorcodes";
import axios from "axios";
import config from "../../../.samples.config";
import moment from "moment";

export const setSummaryForFilterGenerated = isGenerated => {
  return {
    type: isSummaryReGeneratedType,
    payload: isGenerated
  };
};

export const addSummaryForFilter = summaries => {
  return {
    type: summaryForFilter,
    payload: summaries
  };
};

export const clearSummaryForFilter = summaries => {
  return {
    type: removeSummaryForFilter,
    payload: null
  };
};

export const setUserMode = mode => {
  return {
    type: userMode,
    payload: mode
  };
};

export const selectReportView = reportView => {
  return {
    type: reportViewType,
    payload: reportView
  };
};

export const selectReportType = reportType => {
  return {
    type: selectedReportType,
    payload: reportType
  };
};

export const selectActivityType = activityType => {
  return {
    type: selectedActivityType,
    payload: activityType
  };
};

export const resetSAS = () => {
  return {
    type: resetSasType
  };
};

export const fetchEmployeeKey = login => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, FETCH_EMPKEY_FAIL);
    const response = await apiALB(getState().credential.token).get(
      "/employeekey",
      {
        params: { login: login }
      }
    );
    dispatch({ type: fetchEmpKey, payload: response.data });
    // dispatch(isFetched({ method: "isLearnersFetched", status: true }));
  } catch (err) {
    console.log("error in fetching employee key");
    putErrorByDispatch(dispatch, FETCH_EMPKEY_FAIL, err);
  }
};

export const fetchActivityKey = (login, lacode) => async (
  dispatch,
  getState
) => {
  try {
    clearErrorByDispatch(dispatch, FETCH_ACTKEY_FAIL);
    const response = await apiALB(getState().credential.token).get(
      "/activitykey",
      {
        params: { login: login, lacode: lacode }
      }
    );
    dispatch({ type: fetchActivityKeyType, payload: response.data });
  } catch (err) {
    console.log("error in fetching activity key");
    putErrorByDispatch(dispatch, FETCH_ACTKEY_FAIL, err);
  }
};

export const fetchLearners = login => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, FETCH_LEARNERS_FAIL);
    const response = await apiALB(getState().credential.token).get(
      "/employees",
      {
        params: { login: login }
      }
    );
    /* .catch(error => {
        dispatch({
          type: addError,
          payload: { errorCode: FETCH_LEARNERS_FAIL, data: error }
        });
      }) */ dispatch(
      { type: fetchEmployees, payload: response.data }
    );
    // dispatch(isFetched({ method: "isLearnersFetched", status: true }));
  } catch (err) {
    console.log("error in fetching employees");
    putErrorByDispatch(dispatch, FETCH_LEARNERS_FAIL, err);
  }
};

export const addLearner = learner => {
  return {
    type: learnerAdd,
    payload: learner
  };
};
export const removeLearner = learner => {
  return {
    type: learnerRemove,
    payload: learner
  };
};
export const removeAllLearners = () => {
  return {
    type: removeAllLearnersType,
    payload: []
  };
};

export const fetchActivities = login => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, FETCH_ACTIVITIES_FAIL);
    const response = await apiALB(getState().credential.token).get(
      "/activities",
      {
        params: { login: login }
      }
    );
    /* .catch(error => {
        dispatch({
          type: addError,
          payload: { errorCode: FETCH_ACTIVITIES_FAIL, data: error }
        });
      }) */ dispatch(
      {
        type: fetchActivitiesType,
        payload: response.data
      }
    );
  } catch (err) {
    console.log("error in activities");
    putErrorByDispatch(dispatch, FETCH_ACTIVITIES_FAIL, err);
  }
};

export const addActivity = activity => {
  return {
    type: activityAdd,
    payload: activity
  };
};
export const removeActivity = activity => {
  return {
    type: activityRemove,
    payload: activity
  };
};

export const removeActivities = () => {
  return {
    type: removeAllActivities
  };
};

export const fetchLov = () => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, FETCH_LOV_FAIL);

    const response = await apiALB(getState().credential.token).get("/lov");

    dispatch({
      type: fetchLovType,
      payload: response.data
    });
  } catch (err) {
    putErrorByDispatch(dispatch, FETCH_LOV_FAIL, err);
    console.log(err.response + ": error in list of value fetch");
  }
};

export const addStaff = staff => {
  return {
    type: addStaffType,
    payload: staff
  };
};
export const removeStaff = staff => {
  return {
    type: removeStaffType,
    payload: staff
  };
};

export const removeAllStaff = () => {
  return {
    type: removeAllStaffType,
    payload: []
  };
};

export const addEmployeeStatus = employeeStatus => {
  return {
    type: addEmpStatus,
    payload: employeeStatus
  };
};
export const removeEmployeeStatus = employeeStatus => {
  return {
    type: removeEmpStatus,
    payload: employeeStatus
  };
};

export const addEmployeeType = employeeType => {
  return {
    type: addEmpType,
    payload: employeeType
  };
};
export const removeEmployeeType = employeeType => {
  return {
    type: removeEmpType,
    payload: employeeType
  };
};

export const addAssignmentType = assignmentType => {
  return {
    type: addAssignType,
    payload: assignmentType
  };
};
export const removeAssignmentType = assignmentType => {
  return {
    type: removeAssignType,
    payload: assignmentType
  };
};

export const addRegulatoryBody = regBody => {
  return {
    type: addRegBody,
    payload: regBody
  };
};

export const removeRegulatoryBody = regBody => {
  return {
    type: removeRegBody,
    payload: regBody
  };
};

export const addCertification = certification => {
  return {
    type: addCert,
    payload: certification
  };
};

export const removeCertification = certification => {
  return {
    type: removeCert,
    payload: certification
  };
};

export const addAssignmentStatus = assignmentStatus => {
  return {
    type: addAssignStatus,
    payload: assignmentStatus
  };
};

export const removeAssignmentStatus = assignmentStatus => {
  return {
    type: removeAssignStatus,
    payload: assignmentStatus
  };
};

export const generateReportPDF = () => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, GENERATE_PDF_FAIL);
    const reportParam = prepareReportParameters(getState);
    const response = await apiALB(getState().credential.token).post(
      "/generate/pdf",
      {
        executionUser: getState().credential.username,
        summaries: getState().summaryInfo,
        ...reportParam
      }
    );
    dispatch({ type: generatePDF, payload: response.data });
    return null;
  } catch (err) {
    // putErrorByDispatch(dispatch, GENERATE_EXCEL_FAIL, err);
    return err;
  }
};

export const generateReportExcel = () => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, GENERATE_EXCEL_FAIL);
    const reportParam = prepareReportParameters(getState);
    const response = await apiALB(getState().credential.token).post(
      "/generate/excel",
      {
        executionUser: getState().credential.username,
        summaries: getState().summaryInfo,
        ...reportParam
      }
    );

    dispatch({ type: generateExcel, payload: response.data });
    return null;
  } catch (err) {
    return err;
  }
};

export const fetchDrillDown = (rootSrcActivityPK, workForceId) => async (
  dispatch,
  getState
) => {
  try {
    clearErrorByDispatch(dispatch, FETCH_DRILLDOWN_REPORT_FAIL);
    let response = { data: {} };
    if (rootSrcActivityPK > 0 && workForceId > 0) {
      response = await apiALB(getState().credential.token).post(
        "/generate/report/drilldown",
        {
          rootSrcActivityPK: rootSrcActivityPK,
          workForceId: workForceId
        }
      );
    }

    dispatch({
      type: drilldownType,
      payload: JSON.parse(response.data.reports)
    });
    dispatch({
      type: drilldownExecutionTime,
      payload: JSON.parse(response.data.queryExecutionTime)
    });
  } catch (err) {
    console.error("Error in fetch drill down report");
    putErrorByDispatch(dispatch, FETCH_DRILLDOWN_REPORT_FAIL, err);
  }
};

export const sortSummary = (data, column, direction) => {
  const lastItem = data.lastItem;
  var data1 = data.slice(-data.length, data.length - 1);
  data1 = _.sortBy(data1, column);
  if (direction === "descending") {
    data1.reverse();
  }
  data1.push(lastItem);
  return {
    type: summary,
    payload: data1
  };
};

export const sortReport = (data, column, direction) => {
  data = _.sortBy(data, column);
  if (direction === "descending") {
    data.reverse();
  }

  return {
    type: report,
    payload: data
  };
};

export const clearError = errorCode => {
  return {
    type: removeError,
    payload: { errorCode: errorCode }
  };
};

const putErrorByDispatch = (dispatch, errorCode, data) => {
  dispatch({
    type: addError,
    payload: { errorCode: errorCode, data: data }
  });
};

const clearErrorByDispatch = (dispatch, errorCode) => {
  dispatch({
    type: removeError,
    payload: { errorCode: errorCode }
  });
};

export const fetchSummary = () => async (dispatch, getState) => {
  try {
    const reportParam = prepareReportParameters(getState);
    const response = await apiALB(getState().credential.token).post(
      "/generate/summary",
      {
        ...reportParam
      }
    );
    dispatch({
      type: summary,
      payload: JSON.parse(response.data.summaries)
    });

    dispatch({
      type: summaryExecutionTime,
      payload: JSON.parse(response.data.queryExecutionTime)
    });

    dispatch({
      type: thresholdCrossed,
      payload: JSON.parse(response.data.thresholdHit)
    });
  } catch (err) {
    console.log("error in summary");
    putErrorByDispatch(dispatch, FETCH_SUMMARY_FAIL, err);
  }
};

export const clearReport = () => {
  return {
    type: clearReportData
  };
};

export const clearSummary = () => {
  return {
    type: clearSummaryData
  };
};

const prepareReportParameters = getState => {
  const structureKeys = getState()
    .selectedAssignments.map(element => {
      return element.split("$_$")[1];
    })
    .toString();
  const login = getState().credential.loginId;
  const reportType = getState().selectedReportType;
  const empTypes = getState()
    .selectedEmpTypes.toString()
    .split(",")
    .map(data => (data === "" ? data : `'${data.trim()}'`))
    .join(",");
  const empStatus = getState()
    .selectedEmpStatuses.toString()
    .split(",")
    .map(data => (data === "" ? data : `'${data.trim()}'`))
    .join(",");
  const requiredOrRecommended = getState()
    .selectedAssignmentTypes.toString()
    .split(",")
    .map(data => (data === "" ? data : `'${data.trim()}'`))
    .join(",");
  const regulatoryBodies = getState()
    .selectedRegulatoryBodies.toString()
    .split(",")
    .map(data => (data === "" ? data : `'${data.trim()}'`))
    .join(",");
  const certification = getState()
    .selectedCertifications.toString()
    .split(",")
    .map(data => (data === "" ? data : `'${data.trim()}'`))
    .join(",");
  const assignmentStatus = getState()
    .selectedAssignmentStatuses.toString()
    .split(",")
    .map(data => (data === "" ? data : `'${data.trim()}'`))
    .join(",");
  //const structureKeys = getState().selectedAssignments.toString();
  const activityKeys = getState().selectedActivities.toString();
  const workforceKeys =
    getState().selectedStaff.length > 0
      ? getState().selectedStaff.toString()
      : getState().selectedLearners.toString();
  const reportView = !getState().selectedReportView
    ? "condensed"
    : getState().selectedReportView;

  return {
    structureKeys,
    login,
    reportType,
    empTypes,
    empStatus,
    requiredOrRecommended,
    regulatoryBodies,
    certification,
    assignmentStatus,
    activityKeys,
    workforceKeys,
    reportView
  };
};

export const fetchReport = () => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, FETCH_REPORT_FAIL);
    const reportParam = prepareReportParameters(getState);

    const response = await apiALB(getState().credential.token).post(
      "/generate/report",
      {
        ...reportParam
      }
    );

    if (response.data.reports === "thresholdCrossed") {
      dispatch({
        type: report,
        payload: "thresholdCrossed" //JSON.parse(response.data.reports)
      });
      return;
    }

    var reportsData = [];
    const payload = JSON.parse(response.data.reports);
    for (let managerData of payload) {
      for (let staffDataValue of Object.values(managerData.staffData)) {
        //loop staffdatavalues
        for (let avDataValue of Object.values(staffDataValue.avData)) {
          //loop avDataValues
          for (let laDataValue of Object.values(avDataValue.laData)) {
            //loop ladatavalues
            const reportData = createReportData(
              staffDataValue,
              managerData,
              avDataValue,
              laDataValue,
              reportParam.reportView
            );
            reportData.key = generateUUID();
            reportsData.push(reportData);
          }
        }
      }
    }

    dispatch({
      type: report,
      payload: reportsData //JSON.parse(response.data.reports)
    });
    dispatch({
      type: reportExecutionTime,
      payload: JSON.parse(response.data.queryExecutionTime)
    });
  } catch (err) {
    console.log("error in report");
    clearErrorByDispatch(dispatch, FETCH_REPORT_FAIL, err);
  }
};

const createReportData = (
  staffDataValue,
  managerData,
  avDataValue,
  laDataValue,
  reportView
) => {
  const selectedReportView = reportView;
  let reportData = {};
  reportData.staffId = staffDataValue.staffId;
  reportData.studentName = staffDataValue.studentName;
  if (selectedReportView === "full") {
    reportData.employeeStatus = staffDataValue.employeeStatus;
    reportData.loginId = staffDataValue.loginId;
    reportData.employeeType = staffDataValue.employeeType;
    reportData.jobTitle = staffDataValue.jobTitle;
    reportData.managerId = managerData.managerId;
    reportData.managerName = managerData.managerName;
    reportData.costCenterCode = staffDataValue.costCenterCode;
    reportData.costCenterName = staffDataValue.costCenterName;
    reportData.orgUnitCode = staffDataValue.orgUnitCode;
    reportData.orgUnitName = staffDataValue.orgUnitName;
    reportData.personnelSubAreaCode = staffDataValue.personnelSubAreaCode;
    reportData.personnelSubAreaName = staffDataValue.personnelSubAreaName;
    reportData.personnelAreaCode = staffDataValue.personnelAreaCode;
    reportData.personnelAreaName = staffDataValue.personnelAreaName;
    reportData.functionCode = staffDataValue.functionCode;
    reportData.functionName = staffDataValue.functionName;
  }
  reportData.isCertification = laDataValue.isCertification;
  reportData.assignVehicle = avDataValue.assignVehicle;
  reportData.assignVehicleCode = avDataValue.assignVehicleCode;
  reportData.assignVehicleName = avDataValue.assignVehicleName;
  reportData.laCode = laDataValue.laCode;
  reportData.laName = laDataValue.laName;
  reportData.laType = laDataValue.laType;
  reportData.assignStatus = laDataValue.assignStatus;
  reportData.required_recom = laDataValue.required_recom;
  reportData.regulatoryBody = laDataValue.regulatoryBody;
  reportData.assignmentDate = laDataValue.assignmentDate;
  reportData.dueDate = laDataValue.dueDate;
  if (reportData.dueDate) {
    reportData.dueDate = moment(reportData.dueDate).format("MMM DD, YYYY");
  }
  reportData.completionDate = laDataValue.completionDate;
  if (reportData.completionDate) {
    reportData.completionDate = moment(reportData.completionDate).format(
      "MMM DD, YYYY"
    );
  }
  reportData.expirationDate = laDataValue.expirationDate;
  if (reportData.expirationDate) {
    reportData.expirationDate = moment(reportData.expirationDate).format(
      "MMM DD, YYYY"
    );
  }
  reportData.eSignatureDate = laDataValue.eSignatureDate;

  reportData.deCertificationDate = laDataValue.deCertificationDate;
  if (reportData.deCertificationDate) {
    reportData.deCertificationDate = moment(
      reportData.deCertificationDate
    ).format("MMM DD, YYYY");
  }
  reportData.rootActivityKey = laDataValue.rootActivityKey;
  return reportData;
};

export const fetchAssignments = login => async (dispatch, getState) => {
  try {
    clearErrorByDispatch(dispatch, FETCH_ASSIGNMENTS_FAIL);
    const response = await apiALB(getState().credential.token).get(
      "/assignments",
      {
        params: { login: login }
      }
    );
    /*  .catch(error => {
        dispatch({
          type: addError,
          payload: { errorCode: FETCH_ASSIGNMENTS_FAIL, data: error }
        });
      }) */ dispatch(
      {
        type: fetchAllAssignments,
        payload: response.data
      }
    );
  } catch (err) {
    console.log("error in assignments");
    putErrorByDispatch(dispatch, FETCH_ASSIGNMENTS_FAIL, err);
  }
};

export const addAssignment = assignment => {
  return {
    type: assignmentAdd,
    payload: assignment
  };
};
export const removeAssignment = assignment => {
  return {
    type: assignmentRemove,
    payload: assignment
  };
};

export const removeAssignments = () => {
  return {
    type: removeAllAssignments
  };
};

// const api = token => {
//   return axios.create({
//     baseURL: config.webAppConfiguration.sasUrl,
//     headers: {
//       Authorization: `Bearer ${token}`
//       // "Access-Control-Allow-Origin": "*"
//     }
//   });
// };

const apiALB = token => {
  return axios.create({
    baseURL: config.webAppConfiguration.sasalbUrl,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`
    }
  });
};
