import React from "react";
// First way to import
import { PulseLoader } from "react-spinners";


const Progressbar = props => {
  return (
    <React.Fragment>
      <div
        style={{
          top: "50%",
          left: "45%",
          position: "relative",
          fontSize: "17px",
          fontWeight: "bold",
          width: "400px"
        }}
      >
        <div
          style={{
            float: "left"
          }}
        >
          Loading {props.Message}
        </div>
        <div>
          <PulseLoader
            sizeUnit={"px"}
            size={5}
            color={"#123abc"}
            loading={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Progressbar;
