import {
    userCredential
} from "./types";


export const storeCredential = credential => {
  return {
    type: userCredential,
    payload: credential
  };
};


