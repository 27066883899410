import React, { Component } from "react";
import { Table, Segment } from "semantic-ui-react";
import "./Report.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  FETCH_SUMMARY_FAIL,
  FETCH_EMPKEY_FAIL,
  FETCH_ACTKEY_FAIL
} from "../../Common/errorcodes/errorcodes";
import {
  fetchSummary,
  sortSummary,
  addSummaryForFilter,
  addActivity,
  addLearner,
  fetchEmployeeKey,
  fetchActivityKey
} from "../actions";
// import ExecutionTime from "../../Common/controls/ExecutionTime/ExecutionTime";
import Message from "../../Common/controls/Message/Message";
import ErrorMessage from "../../Common/controls/Message/ErrorMessage";
import Progressbar from "../../Common/controls/Progressbar/Progressbar";
import queryString from "query-string";
class Summary extends Component {
  state = {
    direction: null,
    column: null
  };

  handleSort = clickedColumn => {
    const { summaries } = this.props;
    const { column, direction } = this.state;

    if (column !== clickedColumn) {
      this.props.sortSummary(summaries, clickedColumn, "ascending");
      this.setState({
        column: clickedColumn,
        direction: "ascending"
      });
    } else {
      const currentDirection =
        direction === "ascending" ? "descending" : "ascending";
      this.props.sortSummary(summaries, clickedColumn, currentDirection);
      this.setState({
        direction: currentDirection
      });
    }
  };

  componentDidMount() {
    const { credential, hasError, summaries, location } = this.props;
    const url = queryString.parse(location.search);
    if (!(url && url.mode && url.mode.trim() === "self")) {
      if (credential && !summaries && !hasError) {
        this.props.fetchSummary();
      }
    }
  }

  generateSummary() {
    const {
      credential,
      hasError,
      summaries,
      isSummaryForFilterGenerated
    } = this.props;
    if (credential && !summaries && !hasError) {
      this.props.fetchSummary();
    }
    if (!isSummaryForFilterGenerated && summaries) {
      this.props.addSummaryForFilter(this.props.summaries);
    }
  }

  processSelfMode(url) {
    const { credential, empkey, activitykey } = this.props;
    if (credential) {
      if (!empkey) {
        this.props.fetchEmployeeKey(credential.loginId);
      } else {
        if (url && url.LaCode) {
          if (!activitykey) {
            this.props.fetchActivityKey(credential.loginId, url.LaCode.trim());
          } else {
            this.props.addActivity(activitykey);
            this.props.addLearner(empkey);
            this.generateSummary();
          }
        } else {
          this.props.addLearner(empkey);
          this.generateSummary();
        }
      }
    }
  }

  componentDidUpdate() {
    const { location } = this.props;
    const url = queryString.parse(location.search);

    if (url && url.mode && url.mode.trim() === "self") {
      this.processSelfMode(url);
    } else {
      this.generateSummary();
    }
  }

  renderBody() {
    var summariesExceptLastRecord = this.props.summaries.slice(
      -this.props.summaries.length,
      this.props.summaries.length - 1
    );
    const summaryData = summariesExceptLastRecord.map((summary, index) => (
      <Table.Row className="table-row" key={index}>
        <Table.Cell width={5}>{summary.studentName}</Table.Cell>
        <Table.Cell className="textalign">{summary.assignmentCount}</Table.Cell>
        <Table.Cell className="textalign completed">
          {summary.completionCount}
        </Table.Cell>
        <Table.Cell className="textalign incomplete">
          {summary.inCompleteCount}
        </Table.Cell>
        <Table.Cell className="textalign completePercent">
          {summary.percentComplete}
        </Table.Cell>
        <Table.Cell className="textalign">{summary.eSignCount}</Table.Cell>
        <Table.Cell className="textalign">{summary.notEsignCount}</Table.Cell>
        <Table.Cell className="textalign">{summary.percentESign}</Table.Cell>
      </Table.Row>
    ));
    return summaryData;
  }

  renderData() {
    var summariesTotal = this.props.summaries[this.props.summaries.length - 1];
    const { column, direction } = this.state;
    return (
      <div>
        {/* <ExecutionTime time={this.props.summaryQueryExecutionTime} /> */}
        <Table basic="very" striped sortable>
          <Table.Header className="table-head">
            <Table.Row className="table-row">
              <Table.HeaderCell
                width={5}
                sorted={column === "studentName" ? direction : null}
                onClick={() => this.handleSort("studentName")}
              >
                Student
              </Table.HeaderCell>
              <Table.HeaderCell
                className="textalign"
                sorted={column === "assignmentCount" ? direction : null}
                onClick={() => this.handleSort("assignmentCount")}
              >
                Assignment Count
              </Table.HeaderCell>
              <Table.HeaderCell
                className="textalign completed"
                sorted={column === "completionCount" ? direction : null}
                onClick={() => this.handleSort("completionCount")}
              >
                Completed
              </Table.HeaderCell>
              <Table.HeaderCell
                className="textalign incomplete"
                sorted={column === "inCompleteCount" ? direction : null}
                onClick={() => this.handleSort("inCompleteCount")}
              >
                Incompleted
              </Table.HeaderCell>
              <Table.HeaderCell
                className="textalign completePercent"
                sorted={column === "percentComplete" ? direction : null}
                onClick={() => this.handleSort("percentComplete")}
              >
                Complete %
              </Table.HeaderCell>
              <Table.HeaderCell
                className="textalign"
                sorted={column === "eSignCount" ? direction : null}
                onClick={() => this.handleSort("eSignCount")}
              >
                E-Signed Count
              </Table.HeaderCell>
              <Table.HeaderCell
                className="textalign"
                sorted={column === "notEsignCount" ? direction : null}
                onClick={() => this.handleSort("notEsignCount")}
              >
                Not E-Signed Count
              </Table.HeaderCell>
              <Table.HeaderCell
                className="textalign"
                sorted={column === "percentESign" ? direction : null}
                onClick={() => this.handleSort("percentESign")}
              >
                % E-Signed Count
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="scrollContent">{this.renderBody()}</Table.Body>
          <Table.Footer>
            <Table.Row className="table-row table-footer">
              <Table.Cell width={5}>
                <b>TOTAL</b>
              </Table.Cell>
              <Table.Cell className="textalign">
                {summariesTotal.assignmentCount}
              </Table.Cell>
              <Table.Cell className="textalign completed">
                {summariesTotal.completionCount}
              </Table.Cell>
              <Table.Cell className="textalign incomplete">
                {summariesTotal.inCompleteCount}
              </Table.Cell>
              <Table.Cell className="textalign completePercent">
                {summariesTotal.percentComplete}
              </Table.Cell>
              <Table.Cell className="textalign">
                {summariesTotal.eSignCount}
              </Table.Cell>
              <Table.Cell className="textalign">
                {summariesTotal.notEsignCount}
              </Table.Cell>
              <Table.Cell className="textalign tf-lastChild">
                {summariesTotal.percentESign}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }

  render() {
    const { hasError, summaries } = this.props;

    if (hasError) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching summary"
          errorDescription={hasError}
        />
      );
    }

    if (summaries != null) {
      if (summaries.length > 0) {
        return this.renderData();
      }
      return (
        <Segment className="tableAlign">
          <div className="middleDiv">
            <Message display="No records found" />
          </div>
        </Segment>
      );
    }
    return (
      <Segment className="tableAlign">
        <Progressbar Message="Summaries" />
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    summaries: state.summaryInfo,
    credential: state.credential,
    hasError:
      state.errors &&
      state.errors.some(
        code =>
          code.errorCode === FETCH_SUMMARY_FAIL ||
          code.errorCode === FETCH_EMPKEY_FAIL ||
          code.errorCode === FETCH_ACTKEY_FAIL
      ) &&
      state.errors.filter(
        val =>
          val.errorCode === FETCH_SUMMARY_FAIL ||
          val.errorCode === FETCH_EMPKEY_FAIL ||
          val.errorCode === FETCH_ACTKEY_FAIL
      )[0].data,
    selectedReportView: state.selectedReportView,
    isSummaryForFilterGenerated: state.isSummaryForFilterGenerated,
    empkey: state.empkey,
    activitykey: state.activitykey,
    summaryQueryExecutionTime: state.summaryQueryExecutionTime
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchSummary,
      sortSummary,
      addSummaryForFilter,
      addActivity,
      addLearner,
      fetchEmployeeKey,
      fetchActivityKey
    }
  )(Summary)
);
