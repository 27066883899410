import React, { Component } from "react";
import { List } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  selectActivityType,
  removeAssignments,
  removeActivities
} from "./actions";
import { default as Activity } from "../Common/controls/ListItemRadio/ListItemRadio";
import "./sas.css";

class ActivityType extends Component {
  onActivityTypeChange = (e, value) => {
    this.props.selectActivityType(value);
    this.props.removeAssignments();
    this.props.removeActivities();
    
  };

  componentDidMount() {
    const { selectedActivityType } = this.props;
    if (!selectedActivityType) {
      this.props.selectActivityType("all");
    }
  }

  render() {
    return (
      <List>
        <List.Item className="li-style">
          <b>Which Assigned activities do you want to see ?</b>
        </List.Item>
        <Activity
          classname="li_style"
          label="All"
          value="all"
          name="activity_type_group"
          actiontype={this.props.selectedActivityType}
          handlechange={this.onActivityTypeChange}
        />
        <Activity
          name="activity_type_group"
          classname="li_style"
          label="Specific Assignments"
          value="assignments"
          actiontype={this.props.selectedActivityType}
          handlechange={this.onActivityTypeChange}
        />
        <Activity
          classname="li_style"
          label="Specific Learning Activities"
          value="learning_activities"
          actiontype={this.props.selectedActivityType}
          handlechange={this.onActivityTypeChange}
          name="activity_type_group"
        />
      </List>
    );
  }
}

const mapStateToProps = state => {
  return { selectedActivityType: state.selectedActivityType };
};
export default connect(
  mapStateToProps,
  { selectActivityType, removeAssignments, removeActivities }
)(ActivityType);
