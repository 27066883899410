import styles from "./Footer.module.css";
import React from "react";
import { Segment } from "semantic-ui-react";

const Footer = () => {
  return (
    <Segment className={styles.footer}>
      <p className={styles.allrightsText}>
        &copy; 1996-2019 Amgen Inc. All Rights Reserved. Version:{process.env.REACT_APP_VERSION}. Environment: {process.env.REACT_APP_DEPLOYMENT_MODE} 
      </p>
    </Segment>
  );
};
export default Footer;
