import React, { Component } from "react";
import { Button } from "semantic-ui-react";
class Logout extends Component {
  componentDidMount() {
    if (this.props.showNavBar && this.props.authenticated) {
      this.props.hideNavBar();
    }
  }

  redirectToLogin = () => {
    window.location = window.location.origin;
  };

  render() {
    return (
      <div
        style={{
          textAlign: "center",
          height: "60vh",
          margin: "20vh auto",
          width: "60vw",
          fontSize: "18px"
        }}
      >
        <p>You signed out of your account.</p>
        <p>It's a good idea to close all browser windows.</p>
        <div
          style={{
            float: "left",
            marginLeft: "25vw",
            marginTop: "1vh"
          }}
        >
          Click here for
          <Button
            style={{ marginTop: "-1vh", marginLeft: "1vw" }}
            className="button-hover submit-cancle"
            color="blue"
            basic
            onClick={this.redirectToLogin}
          >
            Login
          </Button>
        </div>
      </div>
    );
  }
}

export default Logout;
