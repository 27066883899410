import classes from "./DashBoard.module.css";
import SasIcon from "./SasIcon";
import TasIcon from "./TasIcon";
/* import Instructions from "./Instructions"; */
import React, { Fragment } from "react";
const DashBoard = () => {
  return (
    <Fragment>
      <div className={`row ${classes.sas_text} ${classes.dashBoard}`}>
        <div className={classes.sasIcon}>
          <SasIcon />
        </div>
        {/* { <div className={classes.tasIcon}>
          <TasIcon />
        </div> } */}
      </div>
    </Fragment>
  );
};
export default DashBoard;
