import React, { Component } from "react";
import { List, Checkbox } from "semantic-ui-react";
import {
  fetchAssignments,
  addAssignment,
  removeAssignment,
  removeAssignments
} from "../actions";
import { connect } from "react-redux";
import { default as Assignment } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import Progressbar from "../../Common/controls/Progressbar/Progressbar";
import "../sas.css";
import { FETCH_ASSIGNMENTS_FAIL } from "../../Common/errorcodes/errorcodes";
import Message from "../../Common/controls/Message/Message";
import ErrorMessage from "../../Common/controls/Message/ErrorMessage";

class Assignments extends Component {
  constructor() {
    super();
    this.separator = "$_$";
  }
  state = { checked: false };

  addAllAssignments() {
    const { assignments, selectedAssignments } = this.props;
    for (let i = 0; i < assignments.length; i++) {
      const newAssignment =
        assignments[i].assignment +
        this.separator +
        assignments[i].assignmentid;
      if (!selectedAssignments.includes(newAssignment)) {
        this.props.addAssignment(newAssignment);
      }
    }
  }

  onCheckAllChange(e) {
    if (!e.target.previousSibling.checked) {
      this.addAllAssignments();
    } else {
      this.props.removeAssignments();
    }
  }

  onAssignmentChange = (e, assignment, id) => {
    const newAssignment = assignment + this.separator + id;
    if (!e.target.previousSibling.checked) {
      this.props.addAssignment(newAssignment);
    } else {
      this.props.removeAssignment(newAssignment);
    }
  };

  componentDidMount() {
    const { credential } = this.props;
    if (credential) {
      this.props.fetchAssignments(credential.loginId);
    }
  }

  renderAssignments() {
    const { assignments, selectedAssignments } = this.props;
    return assignments.map(assignment => (
      <Assignment
        key={`${assignment.assignment}_${assignment.assignmentid}`}
        checked={selectedAssignments.some(
          a =>
            a ===
            assignment.assignment + this.separator + assignment.assignmentid
        )}
        classname="list_style"
        label={assignment.assignment}
        name={`${assignment.assignment}_${assignment.assignmentid}`}
        value={assignment.assignmentid}
        handlechange={e =>
          this.onAssignmentChange(
            e,
            assignment.assignment,
            assignment.assignmentid
          )
        }
      />
    ));
  }

  render() {
    const { assignments, hasError, selectedAssignments } = this.props;
    if (hasError) {
      return (
        <ErrorMessage
          errorText="Error occurred in fetching assignments"
          errorDescription={hasError}
        />
      );
    }
    if (assignments != null) {
      if (assignments.length > 0) {
        return (
          <React.Fragment>
            <List.Item>
              <b>
                Select one or more assignments from the list below to include on
                the report .
              </b>
            </List.Item>
            <Checkbox
              label="Select All"
              onChange={e => this.onCheckAllChange(e)}
              checked={selectedAssignments.length === assignments.length}
            />
            <List horizontal className="scroll">
              {this.renderAssignments()}
            </List>
          </React.Fragment>
        );
      }
      return <Message display="No records found" />;
    }
    return (
      <React.Fragment>
        <Progressbar Message="Assignments" />
      </React.Fragment>
    );
  }

  componentDidUpdate() {
    const { credential, hasError, assignments } = this.props;
    if (!assignments && credential && !hasError) {
      this.props.fetchAssignments(credential.loginId);
    }
  }
}

const mapStateToProps = state => {
  return {
    assignments: state.assignments,
    credential: state.credential,
    selectedAssignments: state.selectedAssignments,
    hasError:
      state.errors &&
      state.errors.some(code => code.errorCode === FETCH_ASSIGNMENTS_FAIL) &&
      state.errors.filter(code => code === FETCH_ASSIGNMENTS_FAIL)[0].data
  };
};

export default connect(
  mapStateToProps,
  { fetchAssignments, addAssignment, removeAssignment, removeAssignments }
)(Assignments);
