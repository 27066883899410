import React from "react";
import { List, Radio } from "semantic-ui-react";
import classes from "./ListItemRadio.module.css";
const ListItemRadio = props => {
  const { classname, handlechange, actiontype, value, ...cprops } = props;
  return (
    <List.Item className={classes[classname]}>
      <Radio
        {...cprops}
        checked={actiontype === value}
        value={value}
        onChange={e => handlechange(e, value)}
      />
    </List.Item>
  );
};

export default ListItemRadio;
