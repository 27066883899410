import React, { Component } from "react";
import { Table, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import "./Report.css";
import { FETCH_DRILLDOWN_REPORT_FAIL } from "../../Common/errorcodes/errorcodes";
import Message from "../../Common/controls/Message/Message";
import { fetchDrillDown } from "../actions";
import Progressbar from "../../Common/controls/Progressbar/Progressbar";
import queryString from "query-string";
// import ExecutionTime from "../../Common/controls/ExecutionTime/ExecutionTime";
class DrillDownReport extends Component {
  componentDidMount() {
    const { location } = this.props;
    const url = queryString.parse(location.search);
    if ("key" in url) {
      const data = window.localStorage.getItem(url.key);
      if (!window.sessionStorage.getItem(url.key)) {
        window.sessionStorage.setItem(url.key, data);
        window.localStorage.removeItem(url.key);
      }
    }
  }

  componentDidUpdate() {
    const { credential, drillDownData, hasError, location } = this.props;
    if (credential && !drillDownData && !hasError) {
      const url = queryString.parse(location.search);

      if ("key" in url) {
        const data = JSON.parse(window.sessionStorage.getItem(url.key));
        if (data) {
          this.props.fetchDrillDown(data.rootSrcActivityPK, data.workForceId);
        } else {
          this.props.fetchDrillDown(-1, -1);
        }
      } else {
        this.props.fetchDrillDown(-1, -1);
      }
    }
  }

  renderData() {
    const drillDownData = this.props.drillDownData.map((data, index) => (
      <Table.Row className="table-row" key={index}>
        <Table.Cell className="textalign">{data.staffId}</Table.Cell>
        <Table.Cell className="textalign">{data.studentName}</Table.Cell>
        <Table.Cell className="textalign">{data.loginId}</Table.Cell>
        <Table.Cell className="textalign">{data.laCode}</Table.Cell>
        <Table.Cell className="textalign">{data.laName}</Table.Cell>
        <Table.Cell className="textalign">{data.laType}</Table.Cell>
        <Table.Cell className="textalign">{data.completionStatus}</Table.Cell>
        <Table.Cell className="textalign">{data.completionDate}</Table.Cell>
        <Table.Cell className="textalign">{data.expirationDate}</Table.Cell>
        <Table.Cell className="textalign">{data.eSignatureDate}</Table.Cell>
      </Table.Row>
    ));
    return drillDownData;
  }

  render() {
    if (!this.props.location.search) {
      return (
        <Segment className="tableAlign">
          <Message display="No records found" />
        </Segment>
      );
    }
    const { drillDownData, hasError } = this.props;

    if (hasError) {
      return (
        <Segment className="tableAlign">
          <Message display="Error occurred in fetching drilldown report" />
        </Segment>
      );
    }
    if (drillDownData != null) {
      if (drillDownData.length > 0) {
        return (
          <Segment className="tableAlign">
            {/* <ExecutionTime time={this.props.drilldownQueryExecutionTime} /> */}
            <Table basic="very" striped>
              <Table.Header className="table-head">
                <Table.Row className="table-row">
                  <Table.HeaderCell className="textalign">
                    Staff ID
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    Student Name
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    Login ID
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    LA Name
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    LA Code
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    LA Type
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    Completion Status
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    Last Completion Date
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    Expiration Date
                  </Table.HeaderCell>
                  <Table.HeaderCell className="textalign">
                    E-Signed Date
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body className="scrollContent">
                {this.renderData()}
              </Table.Body>
            </Table>
          </Segment>
        );
      }
      return (
        <Segment className="tableAlign">
          {/* <ExecutionTime time={this.props.drilldownQueryExecutionTime} /> */}
          <Message display="No records found" />
        </Segment>
      );
    }
    return (
      <Segment className="tableAlign">
        <Progressbar Message="Reports" />
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    drilldownQueryExecutionTime: state.drilldownQueryExecutionTime,
    drillDownData: state.drillDownInfo,
    credential: state.credential,
    hasError:
      state.errors &&
      state.errors.some(code => code === FETCH_DRILLDOWN_REPORT_FAIL)
  };
};

export default connect(
  mapStateToProps,
  { fetchDrillDown }
)(DrillDownReport);
