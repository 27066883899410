import React, { Component } from "react";
import { List } from "semantic-ui-react";
import { connect } from "react-redux";
import { selectReportType, removeAllLearners } from "./actions";
import { default as ReportTo } from "../Common/controls/ListItemRadio/ListItemRadio";
import "./sas.css";

class ReportType extends Component {
  onReportTypeChange = (e, value) => {
    this.props.selectReportType(value);
    this.props.removeAllLearners();
  };

  componentDidMount() {
    const { selectedReportType } = this.props;
    if (!selectedReportType) {
      this.props.selectReportType("direct");
    }
  }

  render() {
    return (
      <List>
        <List.Item className="li-style">
          <b>Who should be on this report ?</b>
        </List.Item>
        <ReportTo
          classname="li_style"
          label="Direct Reports Only"
          value="direct"
          actiontype={this.props.selectedReportType}
          handlechange={this.onReportTypeChange}
          name="report_type_group"
        />
        <ReportTo
          classname="li_style"
          label="Direct and indirect Reports"
          value="direct_indirect"
          actiontype={this.props.selectedReportType}
          handlechange={this.onReportTypeChange}
          name="report_type_group"
        />
        <ReportTo
          classname="li_style"
          label="Self or Specific Learners"
          value="specific_learners"
          actiontype={this.props.selectedReportType}
          handlechange={this.onReportTypeChange}
          name="report_type_group"
        />
      </List>
    );
  }
}

const mapStateToProps = state => {
  return { selectedReportType: state.selectedReportType };
};
export default connect(
  mapStateToProps,
  { selectReportType, removeAllLearners }
)(ReportType);
