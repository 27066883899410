import styles from "./DashBoard.module.css";
import sas from "./images/sasmanager.svg";
import React from "react";
import { Link } from "react-router-dom";
const SasIcon = () => {
  return (
    <span className={`${styles.col_items}`}>
      <Link to="/SASReport">
        <img src={sas} height="80hv" width="50%" alt="" />
        <p className={styles.text}>
          Student Assignment Status <br />
          (for Managers and Staff)
        </p>
        <div className={styles.sasIconText}>
          <p>
          Provides the progress of a learner or group of learners <br />
          towards Assignment requirements
          </p>
        </div>
      </Link>
    </span>
  );
};
export default SasIcon;
