import {
  selectedStaffFunction,
  fetchStaffFunctions,
  fetchManagersByDepartment,
  addManager,
  removeManager,
  fetchAVData,
  generateavexcel,
  generateavpdf,
  selectedAssignmentVehicle,
  fetchLAData,
  generatelaexcel,
  generatelapdf,
  addAVManager,
  removeAVManager,
  addAV,
  removeAV,
  addAVLocation,
  removeAVLocation,
  addAVStaff,
  removeAVStaff,
  addCostCenter,
  removeCostCenter,
  addCompletionStatus,
  removeCompletionStatus,
  removeAllSelectedManagers,
  avExecutionTime,
  laExecutionTime
} from "../actions/types";
import { downloadSignedURLJsonFile } from "../../../helpers";
import { async } from "q";

export const selectedStaffFunctionReducer = (staffFunction = null, action) => {
  if (action.type === selectedStaffFunction) {
    return action.payload;
  }
  return staffFunction;
};

export const fetchStaffFunctionsReducer = (staffFunctions = null, action) => {
  if (action.type === fetchStaffFunctions) {
    return action.payload;
  }
  return staffFunctions;
};

export const fetchManagersReducer = (managers = null, action) => {
  if (action.type === fetchManagersByDepartment) {
    return action.payload.data;
  }
  return managers;
};

export const selectedManagersReducer = (selectedmanagers = [], action) => {
  if (action.type === addManager) {
    if (
      selectedmanagers.filter(manager => manager.name === action.payload.name)
        .length === 0
    ) {
      return [...selectedmanagers, action.payload];
    } else {
      return selectedmanagers;
    }
  } else if (action.type === removeManager) {
    return selectedmanagers.filter(
      manager => manager.name !== action.payload.name
    );
  } else if (action.type === removeAllSelectedManagers) {
    return action.payload;
  } else {
    return selectedmanagers;
  }
};

export const fetchAVDataReducer = (avData = null, action) => {
  if (action.type === fetchAVData) {
    //    let response = action.payload.data;

    const parsedData = JSON.parse(action.payload.data);
    return parsedData;
  }
  return avData;
};

export const generateAVReportPdfReducer = (avData = null, action) => {
  if (action.type === generateavpdf) {
    return action.payload;
  }
  return avData;
};

export const generateAVReportExcelReducer = (avData = null, action) => {
  if (action.type === generateavexcel) {
    return action.payload;
  }
  return avData;
};

export const selectedAssignmentVehicleReducer = (
  assignmentvehicle = null,
  action
) => {
  if (action.type === selectedAssignmentVehicle) {
    return action.payload;
  }
  return assignmentvehicle;
};

export const fetchLADataReducer = (laData = null, action) => {
  if (action.type === fetchLAData) {
    return action.payload.data;
  }
  return laData;
};

export const generateLAReportPdfReducer = (laData = null, action) => {
  if (action.type === generatelapdf) {
    return action.payload;
  }
  return laData;
};

export const generateLAReportExcelReducer = (laData = null, action) => {
  if (action.type === generatelaexcel) {
    return action.payload;
  }
  return laData;
};

export const selectedAVManagersReducer = (selectedAVManagers = [], action) => {
  if (action.type === addAVManager) {
    if (
      selectedAVManagers.filter(manager => manager === action.payload)
        .length === 0
    ) {
      return [...selectedAVManagers, action.payload];
    } else {
      return selectedAVManagers;
    }
  } else if (action.type === removeAVManager) {
    return selectedAVManagers.filter(manager => manager !== action.payload);
  } else {
    return selectedAVManagers;
  }
};

export const selectedAVsReducer = (selectedAVs = [], action) => {
  if (action.type === addAV) {
    if (selectedAVs.filter(av => av === action.payload).length === 0) {
      return [...selectedAVs, action.payload];
    } else {
      return selectedAVs;
    }
  } else if (action.type === removeAV) {
    return selectedAVs.filter(av => av !== action.payload);
  } else {
    return selectedAVs;
  }
};

export const selectedStaffsReducer = (selectedStaffs = [], action) => {
  if (action.type === addAVStaff) {
    if (selectedStaffs.filter(staff => staff === action.payload).length === 0) {
      return [...selectedStaffs, action.payload];
    } else {
      return selectedStaffs;
    }
  } else if (action.type === removeAVStaff) {
    return selectedStaffs.filter(staff => staff !== action.payload);
  } else {
    return selectedStaffs;
  }
};

export const selectedLocationsReducer = (selectedLocations = [], action) => {
  if (action.type === addAVLocation) {
    if (
      selectedLocations.filter(location => location === action.payload)
        .length === 0
    ) {
      return [...selectedLocations, action.payload];
    } else {
      return selectedLocations;
    }
  } else if (action.type === removeAVLocation) {
    return selectedLocations.filter(location => location !== action.payload);
  } else {
    return selectedLocations;
  }
};

export const selectedCostCentersReducer = (
  selectedCostCenters = [],
  action
) => {
  if (action.type === addCostCenter) {
    if (selectedCostCenters.filter(cc => cc === action.payload).length === 0) {
      return [...selectedCostCenters, action.payload];
    } else {
      return selectedCostCenters;
    }
  } else if (action.type === removeCostCenter) {
    return selectedCostCenters.filter(cc => cc !== action.payload);
  } else {
    return selectedCostCenters;
  }
};

export const selectedCompletionStatusReducer = (
  selectedCompletionStatus = [],
  action
) => {
  if (action.type === addCompletionStatus) {
    if (
      selectedCompletionStatus.filter(status => status === action.payload)
        .length === 0
    ) {
      return [...selectedCompletionStatus, action.payload];
    } else {
      return selectedCompletionStatus;
    }
  } else if (action.type === removeCompletionStatus) {
    return selectedCompletionStatus.filter(status => status !== action.payload);
  } else {
    return selectedCompletionStatus;
  }
};

export const setAVExecutionTimeReducer = (time = null, action) => {
  if (action.type === avExecutionTime) {
    return action.payload;
  }
  return time;
};

export const setLAExecutionTimeReducer = (time = null, action) => {
  if (action.type === laExecutionTime) {
    return action.payload;
  }
  return time;
};
