import React, { Component, Fragment } from "react";
import { List, Segment } from "semantic-ui-react";
import { default as EmpType } from "../../Common/controls/ListItemCheckBox/ListItemCB";
import { connect } from "react-redux";
import { addEmployeeType, removeEmployeeType } from "../actions";

class EmployeeType extends Component {
  onEmployeeTypeChange = (e, empType, id) => {
    if (!e.target.previousSibling.checked) {
      this.props.addEmployeeType(empType);
    } else {
      this.props.removeEmployeeType(empType);
    }
  };

  renderEmployeeTypes() {
    return this.props.empTypes.map(e => (
      <div
        key={e.empTypeId}
        style={{ display: "inline-block", minWidth: "100px" }}
      >
        <EmpType
          classname=""
          checked={this.props.selectedEmpTypes.includes(e.empType)}
          label={e.empType}
          name={`${e.empType}_${e.empTypeId}`}
          value={e.empTypeId}
          handlechange={event =>
            this.onEmployeeTypeChange(event, e.empType, e.empTypeId)
          }
        />
      </div>
    ));
  }

  render() {
    if (this.props.empTypes) {
      return (
        <Segment raised >
          <h5>
            <b>Select Employee Type</b>
          </h5>
          <div className="scroll" style={{ maxHeight: "20vh" }}>
            <List horizontal>{this.renderEmployeeTypes()}</List>
          </div>
        </Segment>
      );
    }
    return <Fragment></Fragment>;
  }
}

const mapStateToProps = state => {
  return { selectedEmpTypes: state.selectedEmpTypes };
};
export default connect(
  mapStateToProps,
  { addEmployeeType, removeEmployeeType }
)(EmployeeType);
